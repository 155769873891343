<template>
    <div class="ftop">
        <div class="fboxs" ref="ref_fboxs">
          <div class="boxleft" ref="ref_boxleft">
            <div class="flink">
              <div class="ful" v-for="(item,index) in BottomNavData" :key="index">
                <div class="fth">{{item.MenuName}}</div>
                <div class="fli" @click.prevent="linkbtn(it)" v-for="(it,ind) in item.MenuList" :key="ind"><span>{{it.MenuName}}</span></div>
              </div>
              <div class="clear"></div>
            </div>
          </div>
          <div class="boxright" ref="ref_boxright">
            <div class="appbox">
              <div class="abox" v-if="ParamData.APP_WechatMiniAPP">
                <div class="code">
                  <div class="m"><img :src="ParamData.APP_WechatMiniAPP" alt=""/></div>
                  <i class="iconfont">&#xe810;</i>
                </div>
                <div class="rb"><i class="iconfont">&#xe61e;</i><span style="margin-left:1px;">微信小程序</span></div>
              </div>
              <div class="abox" v-if="ParamData.APP_H5">
                <div class="code">
                  <div class="m"><img :src="ParamData.APP_H5" alt=""/></div>
                  <i class="iconfont">&#xe810;</i>
                </div>
                <div class="rb"><i class="iconfont">&#xe614;</i><span style="margin-left:3px;">移动端H5</span></div>
              </div>
              <div class="abox" v-if="ParamData.APP_Android">
                <div class="code">
                  <div class="m"><img :src="ParamData.APP_Android" alt=""/></div>
                  <i class="iconfont">&#xe810;</i>
                </div>
                <div class="rb"><i class="iconfont">&#xe603;</i><span>手机APP(安卓)</span></div>
              </div>
              <div class="abox" v-if="ParamData.APP_IOS">
                <div class="code">
                  <div class="m"><img :src="ParamData.APP_IOS" alt=""/></div>
                  <i class="iconfont">&#xe810;</i>
                </div>
                <div class="rb"><i class="iconfont">&#xe603;</i><span>手机APP(IOS)</span></div>
              </div>
              <div class="clear"></div>
            </div>
            <div class="imgbox">
              <div class="ibox" v-for="(item,index) in BannerData" :key="index">
                <div class="m"><img :src="item.AdImageFileUrl" alt="" /></div>
                <div class="t">{{item.AdTitle}} </div>
              </div>
              <div class="clear"></div>
            </div>
            <div class="clear"></div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
  </template>
  <script>
  import { Message} from 'element-ui';
  import { getStore } from '@/utils/utils';
  import API from "@/api/config";
  export default {
    name: 'BottomMenu',
    data(){
      return {
        BottomNavData:[],
        ParamData:{},
        BannerData:[]
      }
    },
    mounted(){
      let menu = getStore('BottomMenuList');
      if(menu!=null&&menu!=undefined&&menu!=''&&menu!=[]){
        this.BottomNavData = JSON.parse(menu);
      }
      let param = getStore("ParamList");
      if(param!=null&&param!=undefined&&param!=''&&param!=[]){
        JSON.parse(param).map(item => {
            this.$set(this.ParamData, item.ParamKey, item.ParamValue);
        });
      }
      API.Web.GetAdList({AdPositionId:3}).then(res => {
        if(res.Data.Data.length>0){
          this.BannerData = res.Data.Data;
        }
      });
      //底部宽度适配
      this.$refs.ref_boxleft.style.width=(this.$refs.ref_fboxs.clientWidth - this.$refs.ref_boxright.clientWidth - 42)+'px';
    },
    methods:{
      linkbtn(item){
        if(item.MenuPath!='/'){
          if(item.OpenWay==0){
            if(item.MenuPath.indexOf('http')!=-1){
              location.href = item.MenuPath;
            }
            else{
              this.$router.push({path:item.MenuPath});
            }
          }
          else if(item.OpenWay==1){
            if(item.MenuPath.indexOf('http')!=-1){
              window.open(item.MenuPath);
            }
            else{
              window.open(this.$router.resolve({path: item.MenuPath}).href, "_blank");
            }
          }
          else{
            if(item.MenuPath.indexOf('http')!=-1){
              location.href = item.MenuPath;
            }
            else{
              this.$router.push({path:item.MenuPath});
            }
          }
        }
        else{
          Message('功能开发中……');
        }
      }
    }
  }
  </script>
  <style scoped lang="less">
  .ftop{
      width: 100%;
      height: 223px;
      color: #ebebeb;
      background-color: #3f3e3e;
      .fboxs{
            background-color: #3f3e3e;
            max-width: 1420px;
            margin: auto;
            height: 100%;
                .boxleft{
                    width: calc(100% - 490px);
                    float: left;
                    margin-left: 20px;
                    .flink{
                    margin-top: 20px;
                    height: 165px;
                    overflow: hidden;
                    width: 100%;
                    .ful{
                        width: calc(100% / 4);
                        max-width: 180px;
                        float: left;
                        height: 100%;
                        overflow: hidden;
                        user-select: none;
                        .fth{
                        width: 100%;
                        height: 36px;
                        line-height: 30px;
                        font-size: 16px;
                        }
                        .fli{
                        width: 100%;
                        height: 26px;
                        line-height: 26px;
                        span{
                            font-size: 13px;
                        }
                        }
                        .fli span:hover{
                        cursor: pointer;
                        opacity: 0.8;
                        }
                    }
                    }
                }
                .boxright{
                    float: right;
                    margin-right: 20px;
                    width: 490px;
                    .appbox{
                    height: 30px;
                    margin-top: 21px;
                    line-height: 30px;
                    text-align: right;
                    .abox{
                        display: inline-block;
                        position: relative;
                        margin-left: 20px;
                        .rb{
                        font-size: 15px;
                        i{
                            font-size: 18px;
                            float: left;
                            display: block;
                            line-height: 32px;
                        }
                        }
                        .code{
                        width: 100px;
                        height: 0px;
                        text-align: center;
                        position: absolute;
                        left: calc(50% - 50px);
                        bottom: 30px;
                        z-index: 1;
                        overflow: hidden;
                        transition: height 0.3s;
                        -webkit-transition: height 0.3s;
                        .m{
                            border: 1px solid #ddd;
                            background-color: #fff;
                            padding: 6px;
                            overflow: hidden;
                            img{
                            width: 100%;
                            display: block;
                            }
                        }
                        i{
                            display: block;
                            margin-top: -4px;
                            font-size: 14px;
                        }
                        }
                    }
                    .abox:hover{
                        cursor: default;
                    }
                    .abox:hover .code{
                        height: 120px;
                    }
                    .abox:hover .rb{
                        opacity: 0.6;
                    }
                    }
                    .imgbox{
                    float: right;
                    margin-top: 20px;
                    .ibox{
                        width: 100px;
                        float: left;
                        margin-left: 30px;
                        .m{
                        width: 100%;
                        height: 100px;
                        overflow: hidden;
                        img{
                            width: 100%;
                            display: block;
                        }
                        }
                        .t{
                        font-size: 14px;
                        line-height: 40px;
                        text-align: center;
                        }
                    }
                }
            }
        }
    }
  </style>
    