import router from '@/router'
import { pageTitle } from './get_title'
import store from '@/store'
import { Message } from 'element-ui'

let limitList = ['/diagnosis', '/diagnosis-info']

router.beforeEach((to, from, next) => {

    if (limitList.includes(to.path)) {
        store.dispatch('user/judgeLoginStatus').then(flag => {
            if (!flag) {
                Message({
                    type: 'info',
                    message: '请先登录！',
                    showClose: true,
                    center: true
                })
                router.push('/login')
            } else {
                document.title = pageTitle(to.meta.title)
                next()
            }
        })

    } else {

        document.title = pageTitle(to.meta.title)
        next()
    }


})