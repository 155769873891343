import layout from '@/layout'

export default [
    {
        path: '/layout',
        component: layout,
        children: [

            {
                path: '/choose-subject',
                meta: {title: '新高考选科'},
                component: () => import('@/views/tool/chooseSubject/chooseSubject')
            },

            {
                path: '/industry',
                meta: {title: '查行业'},
                component: () => import('@/views/tool/industry/index')
            },
            {
                path: '/industry-detail',
                meta: {title: '查行业'},
                component: () => import('@/views/tool/industry/industry-detail')
            },

            {
                path: '/profession',
                meta: {title: '查职业'},
                component: () => import('@/views/tool/profession/search-profession')
            },
            {
                path: '/profession-list',
                meta: {title: '职业类型列表'},
                component: () => import('@/views/tool/profession/profession-list')
            },
            {
                path: '/profession-typelist',
                meta: {title: '职业类型列表'},
                component: () => import('@/views/tool/profession/typelist'),
            },
            {
                path: '/profession-detail',
                meta: {title: '职业详情'},
                component: () => import('@/views/tool/profession/profession-detail'),
            },
            {
                path: '/profession-seachlist',
                meta: {title: '职业搜索列表'},
                component: () => import('@/views/tool/profession/seachlist'),
            },

            {
                path: '/specialty',
                meta: {title: '查专业'},
                component: () => import('@/views/tool/specialty/search-specialty'),
            },
            {
                path: '/specialty-list',
                meta: {title: '查专业'},
                component: () => import('@/views/tool/specialty/specialty-list'),
            },
            {
                path: '/specialty-detail',
                meta: {title: '查专业'},
                component: () => import('@/views/tool/specialty/specialty-detail'),
            },

            {
                path: '/university',
                meta: {title: '查院校'},
                component: () => import('@/views/tool/university/search-university'),
            },
            {
                path: '/university-detail',
                meta: {title: '院校详情'},
                component: () => import('@/views/tool/university/university-detail'),
            },
            {
                path: '/college-compareCollege',
                meta: {title: '院校对比'},
                component: () => import('@/views/tool/university/compareCollege'),
            },
            {
                path: '/constitution-details',
                meta: {title: '招生章程'},
                component: () => import('@/views/tool/university/constitution-details'),
            },

            {
                path: '/enroll-plan',
                meta: {title: '招生计划'},
                component: () => import('@/views/tool/enroll-plan/query'),
            },
            {
                path: '/enroll-plan-info',
                meta: {title: '招生计划'},
                component: () => import('@/views/tool/enroll-plan/index'),
            },

            {
                path: '/enroll-data',
                meta: {title: '录取数据'},
                component: () => import('@/views/tool/enroll-data/query'),
            },
            {
                path: '/enroll-data-info',
                meta: {title: '录取数据'},
                component: () => import('@/views/tool/enroll-data/index'),
            },

            {
                path: '/specialty-rank',
                meta: {title: '专业排行榜'},
                component: () => import('@/views/tool/specialty-rank/specialty-rank'),
            },

            {
                path: '/college-rank',
                meta: {title: '院校排行榜'},
                component: () => import('@/views/tool/university-rank/university-rank'),
            },

            {
                path: '/diagnosis',
                meta: {title: '诊断成绩换算'},
                component: () => import('@/views/tool/diagnosis/index'),
            },
            {
                path: '/diagnosis-info',
                meta: {title: '诊断成绩换算'},
                component: () => import('@/views/tool/diagnosis/diagnosis-info'),
            },

            {
                path: '/query-batch',
                meta: {title: '查批次'},
                component: () => import('@/views/tool/query/query-batch'),
            },

            {
                path: '/query-rank',
                meta: {title: '查位次'},
                component: () => import('@/views/tool/query/query-rank'),
            },

            
        ]
    },
    
]