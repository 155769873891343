<template>
  <div class="pagination">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :layout="customLayout"
      :background=background
      :small="small"
      :pager-count="pagerCount"
      :current-page="pageIndex"
      :page-sizes="pageSizeList"
      :page-size="pageSize"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
  export default {
    name: "CustomPagination",
    props: {
      layout: {
        type: String
      },
      pagerCount: {
        type: Number,
        default: 7
      },
      background: {
        type: Boolean,
        default: true
      },
      pageIndex: {
        type: Number,
        default: 1
      },
      pageSize: {
        type: Number,
        default: 10
      },
      pageSizeList: {
        type: Array,
        default () {
          return [10, 20, 50, 100]
        }
      },
      small: {
        type: Boolean,
        default: false
      },
      total: {
        type: Number
      }
    },
    data () {
      return {
        customLayout: "total, prev, pager, next",
      }
    },
    mounted () {
      this.layoutSort()
    },
    methods: {
      //分页数量变化
      handleSizeChange (pageSize) {
        this.$emit('handleSizeChange', pageSize)
      },
      //页码改变
      handleCurrentChange (currentPage) {
        this.$emit('handleCurrentChange', currentPage)
      },
      //分页组件布局顺序调整
      layoutSort () {
        if (this.layout&&this.layout.length !== 0) {
          let temp = this.layout
          temp = temp.split(',')
          this.customLayout = ["total", "prev", "pager", "next"].concat(temp)
          if (this.customLayout.includes('sizes')) {
            this.customLayout.removeArrayItem('sizes')
            this.customLayout.splice(1, 0, 'sizes')
          }
          if (this.customLayout.includes('jumper')) {
            this.customLayout.removeArrayItem('jumper')
            this.customLayout.push('jumper')
          }
          this.customLayout = this.customLayout.join(',')
          temp = null
        }
      }
    }
  }
</script>
<style scoped lang="less">
  .pagination {
    padding: 1rem 0;
    text-align: center;
  }
</style>
