<template>
  <div>
    <div class="logobox">
      <div class="logo" @click.prevent="$router.push('/index')"><img :src="ParamData.Web_Logo" /></div>
      <div class="probox" :class="arealock" @mouseover="areaover()">
        <div class="pbtn">
          <span>{{ nowareaname }}</span><i class="iconfont">&#xe600;</i>
        </div>
        <div class="pall">
          <div class="plist">
            <div class="txt" v-for="item in ProvinceData" :key="item.Id" @click.prevent="provincebtn(item)"><span
                class="c">{{ item.PinYinOne }}</span><span class="t">{{ item.ProvinceName }}</span></div>
            <div class="clear"></div>
          </div>
        </div>
      </div>
      <div class="sbox">
        <!-- 搜索 -->
        <div class="sinput">
          <input v-model.trim="searchtxt" placeholder="搜资讯 / 找大学 / 查专业 / 看职业" maxlength="20"
            v-on:keyup.enter="handleEnterSearch" />
          <div class="sbtn" @click="searchbtn()"><i class="iconfont">&#xe635;</i></div>
        </div>
        <!-- 开通、激活 会员 -->
        <div class="vipbox">
          <div class="b3" @click.prevent="$router.push('/openvip');">
            <span>开通 / 激活</span><i class="iconfont">&#xe76c;</i>
          </div>
        </div>
        <!-- 高考倒计时 -->
        <div class="timebox" v-if="timeIsShow">
          <div class="ltxt"></div>
          <div class="nbox">
            <div class="num" v-for="(item, index) in DayData" :key="index"><span>{{ item }}</span><i></i></div>
          </div>
          <span class="t">天</span>
          <div class="clear"></div>
        </div>
        <div class="clear"></div>
      </div>
      <div class="clear"></div>
    </div>

    <!-- 首次选择地区 -->
    <div class="areabox" :class="layerclass" v-if="areaboxshow">
      <div class="layerbox" :class="layerboxclass">
        <div class="tit">请选择地区</div>
        <div class="plist">
          <div class="txt" v-for="item in ProvinceData" :key="item.Id" @click.prevent="provincebtn(item)"><span
              class="c">{{ item.PinYinOne }}</span><span class="t">{{ item.ProvinceName }}</span></div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getExamProvince, getStore, setStore, removeStore } from '../../utils/utils';
import { Message } from 'element-ui';
import API from "@/api/config";
export default {
  data() {
    return {
      searchtxt: '',//查询内容
      areaboxshow: false,//地区弹层
      layerclass: '',
      layerboxclass: '',
      nowareaname: '地区',//当前地区
      ProvinceData: [],//省份数据,
      ParamData: {},
      DayData: [],
      timeIsShow: false,
      arealock: ''
    }
  },
  mounted() {
    //获取logo
    let param = getStore("ParamList");
    if (param != null && param != undefined && param != '' && param != []) {
      JSON.parse(param).map(item => {
        this.$set(this.ParamData, item.ParamKey, item.ParamValue);
      });
    }
    //获取地区
    let plist = getExamProvince();
    if (plist != undefined) {
      this.ProvinceData = JSON.parse(plist);
    }
    let area = getStore('OrgProvince');
    if (area) {
      this.nowareaname = JSON.parse(area).ProvinceName;
      if (this.ParamData.Web_ExamDate == '0') {
        // 距离高考天数
        this.timeIsShow = true;
        API.Web.GetExamCountdown({ examProvinceId: JSON.parse(area).ProvinceId }).then(res => {
          if (res.code === 0 && res.data != null) {
            this.DayData = res.data.toString().split('');
          }
        });
      }
    }
    else {
      this.areaboxshow = true;
    }
  },
  methods: {
    //锁定省份
    areaover() {
      let user = getStore("LoginData");
      if (user == '' || user == undefined || user == null) {
        this.arealock = 'areashow';
      }
      else {
        //登录后是否锁定省份
        let utype = JSON.parse(user).UserType;
        if (utype == 1 || utype == '1') {
          this.arealock = 'areashow';
        }
        else {
          API.User.CheckUserStudentComplate().then(res => {
            if (res.data.IsNeedComplateSubjects==true) {
              this.arealock = 'areashow';
            } else {
              this.arealock = 'areano';
            }
          })
        }
      } 
    },
    //查询Enter键按下
    handleEnterSearch() {
      this.searchbtn();
    },
    //查询
    searchbtn() {
      this.$router.push('/search?keywords=' + this.searchtxt);
    },
    //省份选择 
    provincebtn(item) {
      this.nowareaname = item.ProvinceName;

      removeStore('tempScoreInfo')

      setStore('OrgProvince', item);
      this.layerclass = 'alayerhide';
      this.layerboxclass = "alayerboxhide";
      let st = setTimeout(() => {
        if (this.$route.path == '/index' || this.$route.path == '/') {
          this.$router.push('/refresh');//刷新页面
        }
        else {
          this.$router.push('/index');
        }
        clearTimeout(st);
      }, 300);
    },
  }
}
</script>
<style lang="less" scoped>
/***** logo、查询栏 *****/
.logobox {
  max-width: 1420px;
  margin: auto;
  height: 80px;

  .logo {
    height: 50px;
    overflow: hidden;
    float: left;
    margin-top: 15px;
    margin-left: 20px;

    img {
      height: 100%;
    }
  }

  .logo:hover {
    cursor: pointer;
    opacity: 0.85;
  }

  .areashow:hover .pbtn {
    cursor: pointer;
  }

  .areano:hover .pbtn {
    cursor: no-drop;
  }

  .areashow:hover .pall {
    height: 202px;
  }

  .probox {
    width: 80px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #ddd;
    float: left;
    margin: 24px 0px 0px 30px;
    position: relative;

    .pbtn {
      width: 100%;
      height: 100%;
      text-align: center;
      position: absolute;
      z-index: 12;
      background-color: #fff;
      user-select: none;

      span {
        color: #666;
        font-size: 14px;
      }

      i {
        margin-left: 10px;
        color: #666;
        font-size: 14px;
      }
    }

    .pall {
      width: 440px;
      height: 0px;
      position: absolute;
      left: -1px;
      top: 29px;
      z-index: 11;
      overflow: hidden;
      transition: height 0.3s;
      -webkit-transition: height 0.3s;

      .plist {
        background-color: #fff;
        border: 1px solid #ddd;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        padding: 10px;

        .txt {
          width: calc(100% / 6);
          text-align: center;
          height: 30px;
          line-height: 30px;
          float: left;
          color: #666;
          font-size: 14px;
          overflow: hidden;
          border-radius: 3px;
          margin: 2px 0px 4px;
          transition: background 0.3s;
          -webkit-transition: background 0.3s;
          user-select: none;

          .c {
            font-size: 10px;
          }

          .t {
            margin-left: 5px;
          }
        }

        .txt:hover {
          color: #fff;
          cursor: pointer;
          background: rgb(var(--themecolor));
        }
      }
    }
  }

  .sbox {
    float: right;
    height: 40px;
    margin-top: 20px;

    .timebox {
      height: 40px;
      float: left;
      margin-right: 20px;

      .ltxt {
        width: 36px;
        height: 100%;
        background: url(../../assets/images/timer.png) no-repeat center center / 100%;
        float: left;
      }

      .nbox {
        float: left;
        margin-left: 5px;

        .num {
          width: 28px;
          height: 38px;
          border: 2px solid #cfcfcf;
          border-radius: 5px;
          overflow: hidden;
          float: left;
          background-color: #fff;
          position: relative;
          margin: 0px 3px;
          box-shadow: #bebebe 3px 3px 4px 0px;

          i {
            display: block;
            width: 100%;
            height: 2px;
            background-color: rgba(214, 214, 214, 0.6);
            top: calc(50% - 1px);
            z-index: 2;
            position: absolute;
          }

          span {
            color: rgba(var(--themecolor), 0.75);
            font-size: 30px;
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            z-index: 1;
            line-height: 36px;
            text-align: center;
            font-family: Tahoma;
          }
        }
      }

      .t {
        color: #666;
        font-size: 14px;
        display: block;
        height: 100%;
        width: 14px;
        margin-left: 3px;
        float: right;
        line-height: 42px;
      }
    }

    .sinput {
      width: 280px;
      height: 38px;
      border: 1px solid #ddd;
      background-color: #fff;
      float: left;
      margin-right: 20px;

      input {
        width: 220px;
        border: 0px !important;
        height: 100%;
        line-height: 38px;
        line-height: 1;
        margin-left: 10px;
        color: #666;
        font-size: 14px;
        float: left;
      }

      .sbtn {
        width: 50px;
        height: 100%;
        text-align: center;
        line-height: 38px;
        color: #999;
        font-size: 16px;
        float: left;
        user-select: none;
      }

      .sbtn:hover {
        cursor: pointer;
        color: rgb(var(--themecolor));
      }
    }

    .vipbox {
      width: 140px;
      height: 36px;
      float: left;
      margin-right: 24px;
      position: relative;
      user-select: none;

      .b3 {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        overflow: hidden;
        background-color: #fff;
        position: absolute;
        z-index: 3;
        top: 0px;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        border: 2px solid #f89f32;

        span {
          font-size: 15px;
          display: block;
          float: left;
          line-height: 40px;
          margin-left: 10px;
          color: #333;
          transition: all 0.3s;
          -webkit-transition: all 0.3s;
        }

        i {
          font-size: 44px;
          display: block;
          float: right;
          margin-right: 8px;
          line-height: 40px;
          color: #ecae03;
          transition: all 0.3s;
          -webkit-transition: all 0.3s;
        }
      }

      .b3:hover {
        background-color: #f89f32;
        cursor: pointer;

        span {
          color: #fff;
        }

        i {
          color: #fff;
        }
      }
    }
  }
}

/***** 首次选择地区 *****/
.areabox.alayerhide {
  animation: alayerhide 0.3s ease 0s 1;
  -webkit-animation: alayerhide 0.3s ease 0s 1;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;

  @-webkit-keyframes alayerhide {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes alayerhide {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }
}

.areabox {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 300;
  background-color: rgba(0, 0, 0, 0.5);
  animation: alayershow 0.3s ease 0s 1;
  -webkit-animation: alayershow 0.3s ease 0s 1;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;

  @-webkit-keyframes alayershow {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes alayershow {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .layerbox.alayerboxhide {
    animation: alayerboxhide 0.3s ease 0s 1;
    -webkit-animation: alayerboxhide 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;

    @-webkit-keyframes alayerboxhide {
      from {
        top: calc(50% - 230px);
      }

      to {
        top: -50%;
      }
    }

    @keyframes alayerboxhide {
      from {
        top: calc(50% - 230px);
      }

      to {
        top: -50%;
      }
    }
  }

  .layerbox {
    width: 642px;
    height: 460px;
    position: absolute;
    top: calc(50% - 230px);
    left: calc(50% - 321px);
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    animation: layerboxshow 0.3s ease 0s 1;
    -webkit-animation: layerboxshow 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;

    @-webkit-keyframes layerboxshow {
      from {
        top: -50%;
      }

      to {
        top: calc(50% - 230px);
      }
    }

    @keyframes layerboxshow {
      from {
        top: -50%;
      }

      to {
        top: calc(50% - 230px);
      }
    }

    .tit {
      color: #fff;
      font-size: 18px;
      text-align: center;
      height: 50px;
      line-height: 50px;
      margin-top: 30px;
    }

    .plist {
      margin: 15px;

      .txt {
        float: left;
        width: 80px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        border: 1px solid #747474;
        margin: 10px;
        border-radius: 3px;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;

        .c {
          margin-right: 5px;
        }
      }

      .txt:hover {
        cursor: pointer;
        border: 1px solid rgb(var(--themecolor));
        background-color: rgb(var(--themecolor));
      }
    }
  }
}</style>