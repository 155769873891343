<template>
  <div v-show="value" class="floor-sort">
    <div class="left" @click="clickMask"></div>
    <div id="drag_floor" class="right">
      <div :style="{height: floorHeight}" :class="['floor', { 'selected-item': currentFloor === index, activate: currentFloor === index }]"
        v-for="(item, index) in list" :data-sort="index" :key="index">
        <div class="floor-single">{{ item }}</div>
        <div v-show="currentFloor === index" class="tips-panel">
          <span class="text">{{ showText }}</span>
          <span class="icon-angle"></span>
        </div>
      </div>
    </div>
    <!-- 上下拖动此处滑块可调整志愿顺序——————使用提示语 -->
    <div v-if="showInstructions" id="instructions" class="instructions">上下拖动此处滑块可调整志愿顺序</div>

  </div>
</template>
<script>
import LeaderLine from '@/utils/leader-line.min.js'
import sortable from 'sortablejs'
export default {
  data() {
    return {
      list: 0,

      // 楼层动态高度
      floorHeight: '1%',

      currentFloor: 0,
      showText: 1,

      // 连线对象
      leaderLine: {},
      // mask上的提示框 单独隐藏，下次出现遮罩也不需要出现提示框
      showInstructions: false,
      timer: '', // 定时器对象
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    openIndex: {
      type: Number,
      default: 0
    },
    totalLength: {
      type: Number,
      default: 0
    }
  },
  watch: {
    value(val) {
      if (val) {

        this.currentFloor = this.openIndex
        this.showText = this.openIndex + 1

        this.list = this.totalLength
        this.dragFloor()

        clearTimeout(this.timer)
        this.showInstructions = true

        this.$nextTick(() => {
          this.createLine()
        })

        this.timer = setTimeout(() => {
          if (this.showInstructions) {
            this.leaderLine.remove()
            this.showInstructions = false
          }
        }, 3000)
        // 计算刻度尺高度
        this.floorHeight = (100 / this.totalLength) + '%'
      }
    }
  },
  mounted() { },
  methods: {

    // 创建连线
    createLine() {
      let start = document.getElementById('instructions')
      this.$nextTick(() => {
        let end = document.querySelector('.activate')

        this.leaderLine = new LeaderLine({
          start,
          end,
          size: 4,
          path: 'magnet',
          // dash: {
          //   animation: true
          // },
          gradient: true,

          startPlugColor: 'orange',
          endPlugColor: 'red'
        })
      })
    },

    // 点击选楼层
    selecItem(index) {
      this.currentFloor = index
      this.showText = index + 1

      this.$emit('dragChange', index)
    },
    // 添加拖拽事件
    dragFloor() {
      this.$nextTick(() => {
        let box_floor = document.getElementById('drag_floor')

        let config = {
          animation: 100,
          ghostClass: 'selected-item',// 拖拽的对应页面元素的样式

          handle: '.activate', // 指定class样式的对象才能拖拽

          // chosenClass: 'selected-item', // 鼠标压下去选中时元素的样式，拖拽时抓在手上的样式和页面中元素的样式都有

          // 滑动时清除掉当前选中背景，因为背景中数字不会变，也会挡住下面的显示面板
          dragClass: 'clear-bg', // 抓在手上的样式

          onStart: evt => { },
          onMove: evt => {

            if (this.showInstructions) {
              this.showInstructions = false
              this.leaderLine.remove()
            }

            // 当拖拽经过的元素跟当前正在被拖拽元素的值相等时，判断被拖拽元素处于经过元素的上方还是下方，再调整显示
            // willInsertAfter
            if (Number(this.showText) === Number(evt.related.innerText)) { // 相等
              if (evt.willInsertAfter) { // 下方
                this.showText = Number(this.showText) + 1
                // this.$emit('dragChange', Number(evt.related.dataset.sort) + 1)
              } else {
                this.showText = Number(this.showText) - 1
                // this.$emit('dragChange', Number(evt.related.dataset.sort) - 1)
              }
            } else {
              this.showText = Number(evt.related.innerText)
              // this.$emit('dragChange', Number(evt.related.dataset.sort))
            }
            this.$emit('dragChange', Number(evt.related.dataset.sort))

          },
          onEnd: ({ newIndex, oldIndex }) => {
            this.currentFloor = newIndex
            this.showText = newIndex + 1
            this.list = 0
            setTimeout(() => {
              this.list = this.totalLength
            }, 0)
            this.$emit('sortChange', { newIndex: newIndex, oldIndex: oldIndex }, () => {
              if (this.showInstructions) {
                this.leaderLine.remove()
                this.showInstructions = false
              }

            })
          }
        }

        sortable.create(box_floor, config)
      })


    },

    // 展示和隐藏
    clickMask() {
      this.$emit('input', false)
      if (this.showInstructions) {
        this.leaderLine.remove()
        this.showInstructions = false// mask上的提示框 单独隐藏，下次出现遮罩也不需要出现提示框
      }
    }
  }
}
</script>
<style lang="less" scoped>
.floor-sort {
  user-select: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  display: flex;

  .left {
    width: calc(100% - 30px);

    background-color: rgba(0, 0, 0, 0.1);
  }

  .right {
    width: 30px;
    height: 100vh;
    background-color: rgb(229, 235, 197);

    .floor {
      cursor: pointer;
      color: #655afb;
      margin-bottom: 0.05px;
      position: relative;

      &-single {
        height: 100%;
        // line-height: 100%;
        font-size: 12px;
        transform: scale(0.6);
        text-align: center;
        border-left: 0.1px solid green;
        border-right: 0.1px solid green;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .tips-panel {
        height: 20px;
        border-right: 5px solid transparent;

        display: flex;

        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-100%, -50%);

        .text {
          width: 20px;
          line-height: 20px;
          text-align: center;
          color: #fff;
          background-color: #e50000;
        }

        .icon-angle {
          position: absolute;
          right: -10px;

          border: 5px solid transparent;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left-color: #e50000;
        }
      }
    }
  }

  .selected-item {
    background-color: #e50000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .clear-bg {
    // background-color: transparent;
    box-shadow: none;

    .tips-panel {
      .text {
        background-color: transparent !important;
        color: transparent !important;
      }

      .icon-angle {
        border-left-color: transparent !important;
      }
    }
  }
  .instructions {
    width: 300px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    border-radius: 20px;

    position: fixed;
    left: 50%;
    top: 20px;
    z-index: 2;

  }
}
</style>
<style>
/* 连线的class */
.leader-line {
  z-index: 1000;
}
</style>