import layout from '@/layout'

export default [
    {
        path: '/layout',
        component: layout,
        children: [

            {
                path: '/choose-wish',
                meta: {title: '志愿填报'},
                component: () => import('@/views/choose-wish/index')
            },
            
            {
                path: '/new-exam',
                meta: {title: '志愿填报'},
                component: () => import('@/views/newentrance/index')
            },
        ]
    },
    {
        path: '/wish-table',
        meta: {title: '志愿表'},
        component: () => import('@/views/choose-wish/wish-table')
    },
    {
        path: '/wish-detail',
        meta: {title: '志愿表'},
        component: () => import('@/views/newentrance/newexamdetail')
    },
    {
        path: '/wish-result',
        meta: {title: '志愿表'},
        component: () => import('@/views/newentrance/sqnewexamdetail')
    },
    
]