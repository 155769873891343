import vue from 'vue'
import ConditionScreen from '@/views/custom-components/ConditionScreen'
import CustomPagination from '@/views/custom-components/CustomPagination'
import CustomTree from '@/views/custom-components/CustomTree'
import FloorSort from '@/views/custom-components/FloorSort'

export default [
    vue.component('ConditionScreen', ConditionScreen),
    vue.component('CustomPagination', CustomPagination),
    vue.component('CustomTree', CustomTree),
    vue.component('FloorSort', FloorSort),
]