
import layout from '@/layout'

export default [
    {
        path: '/layout',
        component: layout,
        children: [
            {
                path: '/test',
                meta: { title: '测评' },
                component: () => import('../../views/test/index')
            },
            {
                path: '/MBTI',
                meta: { title: '测评' },
                component: () => import('../../views/test/MBTI')
            },
            {
                path: '/holland-test',
                meta: { title: '测评' },
                component: () => import('../../views/test/hollandTest')
            },
            {
                path: '/personality-test',
                meta: { title: '测评' },
                component: () => import('../../views/test/personalityTest')
            },
            {
                path: '/exam-test',
                meta: { title: '测评' },
                component: () => import('../../views/test/new-exam-choose')
            },
            {
                path: '/exam-test-tips',
                meta: { title: '测评' },
                component: () => import('../../views/test/custom-pages/test-tips')
            },
            {
                path: '/exam-test/choose-province',
                meta: { title: '测评' },
                component: () => import('../../views/test/new-exam-choose/choose-province')
            },
            {
                path: '/exam-test/enter-content',
                meta: { title: '测评' },
                component: () => import('../../views/test/enter-path/enter-content')
            },
            {
                path: '/exam-test/new-test-content',
                meta: { title: '测评' },
                component: () => import('../../views/test/custom-pages/test-content')
            },
            {
                path: '/exam-test/sort-score',
                meta: { title: '测评' },
                component: () => import('../../views/test/new-exam-choose/sort-score')
            },
            {
                path: '/enter-path',
                meta: { title: '测评' },
                component: () => import('../../views/test/enter-path')
            },
            {
                path: '/submit-test',
                meta: { title: '测评' },
                component: () => import('../../views/test/custom-pages/test-submit')
            },


            {
                path: '/test-tips',
                meta: { title: '测评' },
                component: () => import('../../views/test/testTips')
            },
            {
                path: '/test-content',
                meta: { title: '测评' },
                component: () => import('../../views/test/testContent')
            },
            {
                path: '/test-result',
                meta: { title: '测评' },
                component: () => import('../../views/test/testResult')
            },
        ]
    }
]