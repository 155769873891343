
import API from '@/api/config'
import { customSetStorage, customGetStorage} from '@/utils/utils'

const actions = {
    // 系统年份

    getSystemYear() {
        return new Promise((resolve, reject) => {

            let data = customGetStorage('WishYear')
            
            if (data) {
                resolve(data)
            } else {

                API.Wish.WishYear().then((res) => {
                    customSetStorage('WishYear', res.data.Year, 300)
                    resolve(res.data.Year)
                })
            }
        })
    }

}

export default {
    namespaced: true,
    actions
}