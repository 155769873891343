import Vue from 'vue'
function echartsPieOption(type, title, legendArray, seriesDataArray){
  let option
  option = {
    title: {
      text: title
    },
    tooltip: {
      show: true,
      trigger: 'item',
      backgroundColor: 'rgba(0,0,0,.5)',
      textStyle:{
        color: '#ffffff'
      },
      formatter(param){
        let res = param.name +'： '+ param.value + '<br>' + param.data.tips
        return res
      }
    },
    legend: {
      left: 'left',
      orient: 'vertical',
      data: legendArray
    },
    series: [{
      type: type,
      data: seriesDataArray,
    }],
  };
  return option
}
Vue.prototype.$echartsPieOption = echartsPieOption

