<template>
  <div>
    <component :is="item.ModuleCode" v-for="(item,index) in HeaderModuleData" :key="index"></component>

  </div>
</template>
<script>
import Top from '@/components/header/Top.vue';
import Head from '@/components/header/Header.vue';
import HeadMenu from '@/components/header/HeadMenu.vue';
import {getStore} from '@/utils/utils';
export default {
  name: 'pheader',
  components:{
    Top,
    Head,
    HeadMenu
  },
  data(){
    return{
      TopModuleData:['Top','Head','HeadMenu'],
      HeaderModuleData:[]
    }
  },
  mounted(){
    let data = getStore('ModuleList');
    if(data!=null&&data!=undefined&&data!=''&&data!=[]){
      let arr = JSON.parse(data);
      this.TopModuleData.forEach(item=>{
        let obj = arr.find(f=>f.ModuleCode===item);
        if(obj!=undefined){
          this.HeaderModuleData.push(obj);
        }
      });
    }
  },
}
</script>
