import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'
import ls from 'vue-ls'
import './style/index.less';
import './style/base.less'
import ElementUI from 'element-ui';
import * as echarts from 'echarts'
import '@/utils/permission'
import '@/utils/global_components'
import '@/utils/global_function'
import Print from '@/utils/print';

//引入element（按需引入相关组件）参考：https://element.eleme.cn/2.15/#/zh-CN/component/quickstart
import 'element-ui/lib/theme-chalk/index.css';
import { Input,CheckboxGroup,Checkbox,Scrollbar,Tabs,TabPane,Steps,Step,Form,FormItem,Select,Option,Button,Progress,Upload,Tooltip} from 'element-ui';
Vue.use(Input);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Scrollbar);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Select);
Vue.use(Option);
Vue.use(Button);
Vue.use(Progress);
Vue.use(Upload);
Vue.use(Tooltip);

Vue.use(ElementUI);
Vue.use(Print);

const option = {
  namespace: 'JZY_SAAS_',
  name: 'ls',
  storage: 'local' 
}
Vue.use(ls, option)
// bus
Vue.prototype.$globalEventBus = new Vue

Vue.config.productionTip = false;

Vue.prototype.$echarts = echarts

Vue.prototype.$isArray = function (object) {
  return (typeof object === 'object') && object.constructor === Array
}

Vue.directive('removeAriaHidden', {
  bind(el, binding) {
    setTimeout(()=> {
      const ariaEls = el.querySelectorAll('.el-radio__original')

      ariaEls.forEach((item) => {
        item.removeAttribute('aria-hidden')
      })
    }, 2000)
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
