
let seoInfo = localStorage.getItem('WebSEO')

seoInfo = JSON.parse(seoInfo)

let configTitle = ''

seoInfo.some(item => {
    if (item.ParamKey === "WebSEO_Name") {
        configTitle = item.ParamValue

        return true
    }
})

export function pageTitle(title) {
    if (title) {
        return `${configTitle}-${title}`
    }

    return `${configTitle}`
}