<template>
    <div class="boxbottom">
        <div class="bbtxt">
          <span style="margin-right: 20px;">{{ParamData.Web_CopyRight}}</span>
          <a class="hover2" href="https://beian.miit.gov.cn" target="_blank"> {{ParamData.Web_ICP}}</a>
          <a class="hover2" :href="'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' + ParamData.Web_Police.replace(/[^0-9]/g,'') + ''" target="_blank" v-if="ParamData.Web_Police">
            <img src="@/assets/images/gongan.png" alt="" style="margin-top: -3px;"/> {{ParamData.Web_Police}}
          </a>
          <a class="hover2" v-if="ParamData.Web_TechnicalSupport">技术支持：{{ParamData.Web_TechnicalSupport}}</a>
        </div>
        <div class="clear"></div>
      </div>
  </template>
  <script>
  import { getStore } from '@/utils/utils';
  export default {
    name: 'Bottom',
    data(){
      return{
        ParamData:{}
      }
    },
    mounted(){
      let param = getStore("ParamList");
      if(param!=null&&param!=undefined&&param!=''&&param!=[]){
        JSON.parse(param).map(item => {
            this.$set(this.ParamData, item.ParamKey, item.ParamValue);
        });
      }
    }
  }
  </script>
  <style scoped lang="less">
  .boxbottom{
      width: 100%;
      border-top: 1px solid #585858;
      height: 42px;
      line-height: 42px;
      color: #ebebeb;
      background-color: #3f3e3e;
      .bbtxt{
        text-align: center;
        max-width: 1420px;
        margin: auto;
        font-size: 12px;
        a{
          color: #fff;
          margin-right: 20px;
        }
      }
    }
  </style>
    