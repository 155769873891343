import { service } from '@/api/config'
import qs from 'qs'

let bodyHeaders = {
    'Content-Type': 'application/json; charset=utf-8'
}

export default {

    School: {

        SchoolSpecialtyScoreGroup(data) {
            return service({
                url: '/api/School/SchoolSpecialtyScoreGroup',
                headers: bodyHeaders,
                data
            })
        },

        SchoolEnrollmentPlanGroup(data) {
            return service({
                url: '/api/School/SchoolEnrollmentPlanGroup',
                headers: bodyHeaders,
                data
            })
        },

        BaseCondition() {
            return service({
                url: '/api/School/BaseCondition',
            })
        },
        // 院校
        SchoolList(data) {
            return service({
                url: '/api/School/SchoolList',
                headers: bodyHeaders,
                data
            })
        },
        SchoolListBySchoolName(data) {
            return service({
                url: '/api/School/SchoolListBySchoolName',
                headers: bodyHeaders,
                data
            })
        },
        SchoolInfo(data) {
            return service({
                url: '/api/School/SchoolInfo',
                headers: bodyHeaders,
                data
            })
        },
        SchoolAlumnus(data) {
            return service({
                url: '/api/School/SchoolAlumnus',
                headers: bodyHeaders,
                data
            })
        },
        SchoolSpecialty(data) {
            return service({
                url: '/api/School/SchoolSpecialty',
                headers: bodyHeaders,
                data
            })
        },
        SchoolSelectExam(data) {
            return service({
                url: '/api/School/SchoolSelectExam',
                headers: bodyHeaders,
                data
            })
        },

        SchoolEnrollment(data) {
            return service({
                url: '/api/School/SchoolEnrollment',
                headers: bodyHeaders,
                data
            })
        },
        SchoolEnrollmentDirection(data) {
            return service({
                url: '/api/School/SchoolEnrollmentDirection',
                headers: bodyHeaders,
                data
            })
        },
        SchoolEnrollmentPlan(data) {
            return service({
                url: '/api/School/SchoolEnrollmentPlan',
                headers: bodyHeaders,
                data
            })
        },
        SchoolSpecialtyScoreLine(data) {
            return service({
                url: '/api/School/SchoolSpecialtyScoreLine',
                headers: bodyHeaders,
                data
            })
        },
        CompareCollege(data) {
            return service({
                url: '/api/School/CompareCollege',
                headers: bodyHeaders,
                data
            })
        },
        SchoolConstitutionInfo(data) {
            return service({
                url: '/api/School/SchoolConstitutionInfo',
                headers: bodyHeaders,
                data
            })
        },

        SchoolConstitutionList(data) {
            return service({
                url: '/api/School/SchoolConstitutionList',
                headers: bodyHeaders,
                data
            })
        },
        SchoolConstitutionInfo(data) {
            return service({
                url: '/api/School/SchoolConstitutionInfo',
                headers: bodyHeaders,
                data
            })
        },
        SchoolHistory(data) {
            return service({
                url: '/api/School/SchoolHistory',
                headers: bodyHeaders,
                data
            })
        },
        SchoolRanking(data) {
            return service({
                url: '/api/School/SchoolRanking',
                headers: bodyHeaders,
                data
            })
        },
        SchoolRankingType(data) {
            return service({
                url: '/api/School/SchoolRankingType',
                headers: bodyHeaders,
                data
            })
        },
        SchoolIndicate(data) {
            return service({
                url: '/api/School/SchoolIndicate',
                headers: bodyHeaders,
                data
            })
        },
        LaboratoryGroupByCategory(data) {
            return service({
                url: '/api/School/LaboratoryGroupByCategory',
                headers: bodyHeaders,
                data
            })
        },

        SpecialtyAssessment(data) {
            return service({
                url: '/api/School/SpecialtyAssessment',
                headers: bodyHeaders,
                data
            })
        },
        LearnSubjectAssessment(data) {
            return service({
                url: '/api/School/LearnSubjectAssessment',
                headers: bodyHeaders,
                data
            })
        },

        SelAnalysisSpecialtyOfSelectExam(data) {
            return service({
                url: '/api/School/SelAnalysisSpecialtyOfSelectExam',
                headers: bodyHeaders,
                data
            })
        },
        SelAnalysisSelectExamBySpecialtyCategory(data) {
            return service({
                url: '/api/School/SelAnalysisSelectExamBySpecialtyCategory',
                headers: bodyHeaders,
                data
            })
        },
        SelAnalysisSpecialtyOfSelectExamGroup(data) {
            return service({
                url: '/api/School/SelAnalysisSpecialtyOfSelectExamGroup',
                headers: bodyHeaders,
                data
            })
        },
        SchoolListBySchoolName(data) {
            return service({
                url: '/api/School/SchoolListBySchoolName',
                headers: bodyHeaders,
                data
            })
        },
    },

    Specialty: {
        // 专业
        SpecialtyHotList(data) {
            return service({
                url: '/api/Specialty/SpecialtyHotList',
                headers: bodyHeaders,
                data
            })
        },
        SpecialtyCategoryList(data) {
            return service({
                url: '/api/Specialty/SpecialtyCategoryList',
                headers: bodyHeaders,
                data
            })
        },
        SpecialtySearch(data) {
            return service({
                url: '/api/Specialty/SpecialtySearch',
                headers: bodyHeaders,
                data
            })
        },
        SpecialtyList(data) {
            return service({
                url: '/api/Specialty/SpecialtyList',
                headers: bodyHeaders,
                data
            })
        },
        SpecialtyInfo(data) {
            return service({
                url: '/api/Specialty/SpecialtyInfo',
                headers: bodyHeaders,
                data
            })
        },
        SpecialtySchoolList(data) {
            return service({
                url: '/api/Specialty/SpecialtySchoolList',
                headers: bodyHeaders,
                data
            })
        },
        SpecialtyRankList(data) {
            return service({
                url: '/api/Specialty/SpecialtyRankList',
                headers: bodyHeaders,
                data
            })
        },
        SpecialtyOrCategorySearch(data) {
            return service({
                url: '/api/Specialty/SpecialtyOrCategorySearch',
                headers: bodyHeaders,
                data
            })
        },
        SpecialtyOrCategorySearch(data) {
            return service({
                url: '/api/Specialty/SpecialtyOrCategorySearch',
                headers: bodyHeaders,
                data
            })
        },
        SpecialtyOrCategorySearch(data) {
            return service({
                url: '/api/Specialty/SpecialtyOrCategorySearch',
                headers: bodyHeaders,
                data
            })
        },
        SpecialtyFullList(data) {
            return service({
                url: '/api/Specialty/SpecialtyFullList',
                headers: bodyHeaders,
                data
            })
        },

    },


    Profession: {

        // 职业
        CategoryList(data) {
            return service({
                url: '/api/Profession/CategoryList',
                headers: bodyHeaders,
                data
            })
        },
        ProfessionSearchByName(data) {
            return service({
                url: '/api/Profession/ProfessionSearchByName',
                headers: bodyHeaders,
                data
            })
        },
        ProfessionSearchByInterestCode(data) {
            return service({
                url: '/api/Profession/ProfessionSearchByInterestCode',
                headers: bodyHeaders,
                data
            })
        },
        ProfessionList(data) {
            return service({
                url: '/api/Profession/ProfessionList',
                headers: bodyHeaders,
                data
            })
        },
        ProfessionInfo(data) {
            return service({
                url: '/api/Profession/ProfessionInfo',
                headers: bodyHeaders,
                data
            })
        },
        ProfessionHotList(data) {
            return service({
                url: '/api/Profession/ProfessionHotList',
                headers: bodyHeaders,
                data
            })
        },
        ProfessionRecommendList(data) {
            return service({
                url: '/api/Profession/ProfessionRecommendList',
                headers: bodyHeaders,
                data
            })
        },

    },


    Career: {
        // 行业
        // /api/Career/IndustryList
        IndustryList(data) {
            return service({
                url: '/api/Career/IndustryList',
                headers: bodyHeaders,
                data
            })
        },
        IndustryInfo(data) {
            return service({
                url: '/api/Career/IndustryInfo',
                headers: bodyHeaders,
                data
            })
        },

        SelectExamStat(data) {
            return service({
                url: '/api/Career/SelectExamStat',
                headers: bodyHeaders,
                data
            })
        },
        SelectExamList(data) {
            return service({
                url: '/api/Career/SelectExamList',
                headers: bodyHeaders,
                data
            })
        },
        SelectExamPaged(data) {
            return service({
                url: '/api/Career/SelectExamPaged',
                headers: bodyHeaders,
                data
            })
        },
        SelectExamStatTotalNumber(data) {
            return service({
                url: '/api/Career/SelectExamStatTotalNumber',
                headers: bodyHeaders,
                data
            })
        },
        // 培训课程
        CourseCategoryList(data) {
            return service({
                url: '/api/Career/CourseCategoryList',
                headers: bodyHeaders,
                data
            })
        },
        CourseFreeList() {
            return service({
                url: '/api/Career/CourseFreeList',
                headers: bodyHeaders,
            })
        },
        CourseInfoList(data) {
            return service({
                url: '/api/Career/CourseInfoList',
                headers: bodyHeaders,
                data
            })
        },
        PersonalityTest(data) {
            return service({
                url: '/api/Career/PersonalityTest',
                data
            })
        },
        HollandTest(data) {
            return service({
                url: '/api/Career/HollandTest',
                data
            })
        },
        MBTITest(data) {
            return service({
                url: '/api/Career/MBTITest',
                data
            })
        },
        PersonalityQuestionList(data) {
            return service({
                url: '/api/Career/PersonalityQuestionList',
                data
            })
        },
        HollandQuestionList(data) {
            return service({
                url: '/api/Career/HollandQuestionList',
                data
            })
        },
        MBTIQuestionList(data) {
            return service({
                url: '/api/Career/MBTIQuestionList',
                data
            })
        },
        AllroundQuestionList(data) {
            return service({
                url: '/api/Career/AllroundQuestionList',
                data
            })
        },
        AddAllroundTest(data){
            return service({
                url: '/api/Career/AddAllroundTest',
                data
            })
        },
        GetAllroundResult(data){
            return service({
                url: '/api/Career/GetAllroundResult',
                data
            })
        },
        GetAllroundInterestResult(data){
            return service({
                url: '/api/Career/GetAllroundInterestResult',
                data
            })
        },
        GetAllroundInterestAdvise(data){
            return service({
                url: '/api/Career/GetAllroundInterestAdvise',
                data
            })
        },
        GetAllroundCharacterResult(data){
            return service({
                url: '/api/Career/GetAllroundCharacterResult',
                data
            })
        },
        GetAllroundAbilityResult(data){
            return service({
                url: '/api/Career/GetAllroundAbilityResult',
                data
            })
        },
        GetAllroundAbilityAdvise(data){
            return service({
                url: '/api/Career/GetAllroundAbilityAdvise',
                data
            })
        },
        GetAllroundValueResult(data){
            return service({
                url: '/api/Career/GetAllroundValueResult',
                data
            })
        },
        GetAllroundSubjectResult(data){
            return service({
                url: '/api/Career/GetAllroundSubjectResult',
                data
            })
        },
        GetAllroundMajor(data){
            return service({
                url: '/api/Career/GetAllroundMajor',
                data
            })
        },
        SelSubjectCategoryOfProvinceList(data) {
            return service({
                url: '/api/Career/SelSubjectCategoryOfProvinceList',
                data
            })
        },
        SelSubjectTest(data) {
            return service({
                url: '/api/Career/SelSubjectTest',
                data
            })
        },
        UpgradePathQuestionList(data) {
            return service({
                url: '/api/Career/UpgradePathQuestionList',
                data
            })
        },
        SelSubjectQuestionList(data) {
            return service({
                url: '/api/Career/SelSubjectQuestionList',
                data
            })
        },
        UpgradePathTest(data) {
            return service({
                url: '/api/Career/UpgradePathTest',
                data
            })
        },

    },
}