import layout from '@/layout'

export default [
    {
        path: 'layout',
        component: layout,
        children: [
            {
                path: '/study',
                meta: {title: '在线学习'},
                component: () => import('@/views/online/onlineStudy')
            },
            {
                path: '/study-detail',
                meta: {title: '在线学习'},
                component: () => import('@/views/online/onlineStudyDetails')
            },
        ]
    }
]