<template>
  <div>
    <div class="navbox">
      <div class="navback" :style="headernavstyle">
        <div class="navlist">
          <div class="nl">
            <div class="na" :class="(item.MenuPath==''||item.MenuPath==null)?'':'path'" v-for="item in NavData" :key="item.Id" @click.prevent="navbtn(item)">
              <span><i class="iconfont oneicon" v-if="item.MenuIcon" v-html="item.MenuIcon"></i>{{ item.MenuName }}</span>
              <i v-if="item.MenuList.length > 0" class="iconfont">&#xe600;</i>
              <div class="hbox" v-if="item.MenuList" :class="(item.MenuList.length > 0 ? 'padding' : '')" :style="{ width: (item.SubMenuDisplayWay == 0 ? 150 : (150 * item.MenuList.length)) + 'px' }">
                <div class="ubox" :style="{ float: '' + (item.SubMenuDisplayWay == 0 ? 'none' : 'left') + '' }" :class="(item.SubMenuDisplayWay == 0 ? '' : 'uboxborder')" v-for="at in item.MenuList" :key="at.Id">
                  <span class="ut" :class="(at.MenuPath==''||at.MenuPath==null)?'':'path'" @click.prevent="navbtn(at)"><i class="iconfont" v-if="at.MenuIcon" v-html="at.MenuIcon"></i>{{ at.MenuName }}</span>
                  <span class="ua" v-for="bt in at.MenuList" :key="bt.Id" @click.prevent.stop="navbtn(bt)"><i class="iconfont icon" v-if="bt.MenuIcon" v-html="bt.MenuIcon"></i>{{ bt.MenuName }}</span>
                </div>
                <div class="clear"></div>
              </div>
              <div class="clear"></div>
            </div>
            <div class="clear"></div>
          </div>
          <div class="nr">
            <div class="kf" v-if="ParamData.KF_Enable=='0'" @click="customerbtn(ParamData.KF_Url)"><i class="iconfont">&#xe601;</i><span>客服</span></div>
            <!-- 是否登录 -->
            <div class="dl" v-if="loginstatus" @click.prevent="$router.push('/personal')">
              <div class="d"><i class="iconfont i1">&#xe612;</i><span>{{ username }}</span><i
                  class="iconfont i2">&#xe600;</i></div>
              <div class="personalnav">
                <div class="perlist">
                  <div class="pl" @click.prevent.stop="$router.push('/userinfo')">基本信息</div>
                  <div class="pl" @click.prevent.stop="$router.push('/setpwd')">密码设置</div>
                  <div class="pl" @click.prevent.stop="exitlogin()">退出登录</div>
                </div>
              </div>
            </div>
            <div class="dl" v-else>
              <div class="d" @click.prevent="$router.push('/login')"><i class="iconfont">&#xe612;</i><span>登录/注册</span>
              </div>
            </div>
          </div>
          <div class="clear"></div>
        </div>
        <div class="clear"></div>
      </div>
      <div class="clear"></div>
    </div>
    <div class="clear"></div>

    <!-- 返回顶部 -->
    <div class="back_top hover2" v-if="backtopshow">
      <div class='t' @click="backpagetop()"></div>
    </div>
  </div>
</template>
<script>
import API from '@/api/config'
import { Message } from 'element-ui';
import { getStore, setStore, removeStore } from '@/utils/utils';
import { whiteList } from '@/utils/data';
export default {
  data() {
    return {
      headernavstyle: '',//菜单悬浮样式(通过首页index监听滚动事件控制)
      loginstatus: false,//是否登录
      username: '',
      navhovervalue: '',//菜单鼠标选中的值
      backtopshow: false,//返回顶部(通过首页index监听滚动事件控制)
      NavData: [],
      ParamData:[]
    }
  },
  mounted() {

    // 获取菜单数据
    let menu = getStore('HeadMenuList');
    if (menu != null && menu != undefined && menu != '' && menu != []) {
      this.NavData = JSON.parse(menu);
    }

    //内容页自动滚动到菜单位置
    // if (this.$route.path != '/' && this.$route.path != '/index') {
    //   this.$nextTick(function () {
    //     window.scrollTo(0, 121);
    //   });
    // }

    //获取用户登录信息
    let user = getStore("LoginData");
    if (user != undefined && user != null && user != "") {
      user = JSON.parse(user);
      this.loginstatus = true;
      this.username = user.NickName;
    }

    //获取参数信息
    let param = getStore("ParamList");
    if(param!=null&&param!=undefined&&param!=''&&param!=[]){
      JSON.parse(param).map(item => {
          this.$set(this.ParamData, item.ParamKey, item.ParamValue);
      });
    }
    
    //监听滚动事件
    window.addEventListener('scroll', () => {
      let ddeht = document.documentElement.scrollTop || document.body.scrollTop;
      if (ddeht > 120) {
        this.headernavstyle = "position: fixed;top: 0px;left: 0px;z-index: 99;";
      }
      else {
        this.headernavstyle = "";
      }
      if (ddeht > 500) {
        this.backtopshow = true;
      }
      else {
        this.backtopshow = false;
      }
    }, true);
  },
  methods: {
    //菜单点击
    navbtn(item) {
      if(item.MenuPath!=null&&item.MenuPath!=''){
        // 处理参数
        if(item.MenuPath.indexOf('?')>-1){
          //获取用户登录信息
          let user = getStore("LoginData");
          if (user != undefined && user != null && user != "") {
            user = JSON.parse(user);
          }
          else{
            if(item.MenuPath.indexOf('{userid}')>-1||item.MenuPath.indexOf('{token}')>-1||item.MenuPath.indexOf('{nickname}')>-1){
              Message({ type: 'error', message: "请先登录！" });
              this.$router.push({ path: '/login' });
              return false;
            }
          }
          if(item.MenuPath.indexOf('{userid}')>-1){
            item.MenuPath = item.MenuPath.replace('{userid}',user.UserId);
          }
          if(item.MenuPath.indexOf('{token}')>-1){
            item.MenuPath = item.MenuPath.replace('{token}',user.Token);
          }
          if(item.MenuPath.indexOf('{nickname}')>-1){
            item.MenuPath = item.MenuPath.replace('{nickname}',user.NickName);
          }
        }
        if (item.MenuPath == '/') {
          this.$router.push({ path: '/develop' });
        }
        else{
          if (item.OpenWay == 0) {
            if (item.MenuPath.indexOf('http') != -1) {
              location.href = item.MenuPath;
            }
            else {
              this.$router.push({ path: item.MenuPath });
            }
          }
          else if (item.OpenWay == 1) {
            if (item.MenuPath.indexOf('http') != -1) {
              window.open(item.MenuPath);
            }
            else {
              window.open(this.$router.resolve({ path: item.MenuPath }).href, "_blank");
            }
          }
          else {
            if (item.MenuPath.indexOf('http') != -1) {
              window.open(item.MenuPath);
            }
            else {
              this.$router.push({ path: item.MenuPath });
            }
          }
        }
      }
    },
    //客服
    customerbtn(url) {
      window.open(url);
    },
    //退出登录
    exitlogin() {
      this.$store.dispatch('user/loginOut').then(() => {
        this.loginstatus = false;
        this.username = "";
      })
      // API.Web.Loginout().then(res => {
      //   this.loginstatus = false;
      //   this.username = "";
      //   removeStore("LoginData");
      //   if (whiteList.indexOf(this.$route.path) === -1) {
      //     this.$router.push("/index");
      //   }
      // })
    },
    //返回顶部、滚动效果
    backpagetop() {
      let sltop = document.documentElement.scrollTop || document.body.scrollTop;
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = sltop -= 200;
        if (sltop <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  }
}
</script>
<style lang="less" scoped>
/***** 菜单栏 *****/
.navbox {
  height: 40px;
  width: 100%;

  .navback {
    width: 100%;
    height: 40px;
    background-color: rgb(var(--themecolor));
  }

  .navlist {
    max-width: 1420px;
    margin: auto;

    .nl {
      float: left;
      height: 40px;
      margin-left: 18px;

      .na {
        padding: 0px 19px;
        float: left;
        height: 40px;
        line-height: 40px;
        margin-left: 2px;
        position: relative;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        user-select: none;

        .oneicon {
          margin-right: 5px;
          font-size: 16px;
        }

        span {
          color: #fff;
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 1px;
          display: block;
          float: left;
        }

        i {
          color: #eee;
          font-size: 14px;
          margin-left: 3px;
          font-weight: normal;
        }

        .hback {
          width: 100%;
          height: 100%;
          opacity: 0.8;
        }

        .hbox.padding {
          padding: 10px 0px;
        }

        .hbox {
          background-color: rgba(var(--themecolor), 0.9);
          position: absolute;
          left: 0px;
          top: 40px;
          z-index: 30;
          display: none;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          .ubox {
            display: flex;
            flex-direction: column;

            .ut {
              color: #e6e6e6;
              font-size: 15px;
              display: block;
              width: calc(150px - 21px);
              text-align: center;
              margin: 0px 10px;
              transition: all 0.3s;
              -webkit-transition: all 0.3s;

              i {
                margin-right: 5px;
                font-size: 18px;
              }
            }

            .ut.path:hover {
              cursor: pointer;
              background: rgba(0, 0, 0, 0.3);
            }

            .ua {
              color: #fff;
              font-size: 14px;
              display: block;
              width: calc(150px - 21px);
              text-align: center;
              margin: 0px 10px;
              border-radius: 2px;
              transition: all 0.3s;
              -webkit-transition: all 0.3s;

              .icon {
                font-size: 14px;
                margin-right: 4px;
              }
            }

            .ua:first-child {
              margin-top: 10px;
            }

            .ua:hover {
              cursor: pointer;
              background: rgba(0, 0, 0, 0.3);
            }
          }

          .uboxborder:nth-child(2n) {
            border-left: 1px dashed #c2c2c2;
            border-right: 1px dashed #c2c2c2;
          }
        }
      }

      .na.path:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.2);
      }

      .na:hover .hbox {
        display: block;
        animation: hboxshow 0.5s ease 0s 1;
        -webkit-animation: hboxshow 0.5s ease 0s 1;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;

        @-webkit-keyframes hboxshow {
          from {
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }

        @keyframes hboxshow {
          from {
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }
      }
    }

    .nr {
      float: right;
      height: 40px;
      margin-right: 20px;
      line-height: 40px;

      .kf {
        color: #fff;
        float: left;
        padding: 0px 15px;
        transition: background 0.3s;
        -webkit-transition: background 0.3s;
        user-select: none;

        i {
          font-size: 20px;
          display: block;
          float: left;
        }

        span {
          font-size: 14px;
          margin-left: 5px;
          letter-spacing: 1px;
        }
      }

      .kf:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.1);
      }

      .dl {
        float: left;
        position: relative;
        user-select: none;
        min-width: 100px;

        .d {
          color: #fff;
          float: left;
          padding: 0px 15px;
          transition: background 0.3s;
          -webkit-transition: background 0.3s;

          i.i1 {
            font-size: 17px;
            display: block;
            float: left;
          }

          i.i2 {
            font-size: 14px;
            display: block;
            float: right;
            margin-left: 4px;
            transform: rotate(0deg);
            transition: all 0.3s;
            -webkit-transition: all 0.3s;
          }

          span {
            font-size: 14px;
            margin-left: 5px;
            letter-spacing: 1px;
          }
        }

        .d:hover {
          cursor: pointer;
          background: rgba(0, 0, 0, 0.1);
        }

        .personalnav {
          position: absolute;
          width: 100%;
          left: 0;
          top: 40px;
          z-index: 200;
          background-color: #fff;
          display: none;

          .perlist {
            width: calc(100% - 2px);
            border: 1px solid #ddd;
            overflow-x: hidden;

            .pl {
              margin: 5px 10px;
              text-align: center;
              height: 40px;
              line-height: 40px;
              color: #555;
              font-size: 14px;
              border-radius: 2px;
              transition: all 0.2s;
              -webkit-transition: all 0.2s;
            }

            .pl:hover {
              cursor: pointer;
              background-color: rgba(var(--themecolor), 0.2);
            }
          }
        }
      }

      .dl:hover .d .i2 {
        transform: rotate(180deg);
      }

      .dl:hover .personalnav {
        display: block;
        animation: hboxshow 0.5s ease 0s 1;
        -webkit-animation: hboxshow 0.5s ease 0s 1;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
      }
    }
  }
}

/***** 返回顶部 *****/
.back_top {
  width: 48px;
  height: 48px;
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 5;

  .t {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background: url(../../assets/images/backtop.png) no-repeat center center / 70%;
  }

  .t:hover {
    animation: btclass 1s ease 0s infinite;
    -webkit-animation: btclass 1s ease 0s infinite;

    @-webkit-keyframes btclass {
      0% {
        top: 0px;
      }

      50% {
        top: -10px;
      }

      100% {
        top: 0px;
      }
    }

    @keyframes btclass {
      0% {
        top: 0px;
      }

      50% {
        top: -10px;
      }

      100% {
        top: 0px;
      }
    }
  }
}
</style>