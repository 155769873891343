import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
import tool from './modules/tool'

Vue.use(Vuex)

export default new Vuex.Store({
  
  // 如果将store分成一个个的模块的话，则需要用到modules。
  //然后在每一个module中写state, getters, mutations, actions等。
  modules:{
    // a: moduleA,
    // b: moduleB,
    tool,
    user,
  },
  getters,

});