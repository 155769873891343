import axios from 'axios';
export default {
    //获取微信配置
    getConfig(data) {
        return axios.post('/weixin/getConfig', data);
    },
    //获取Oss Sign
    getOssSign() {
        return axios.post('/oss/ossSign');
    },
    //上传图片
    putImageToOss(url, formData) {
        let config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }; //添加请求头
        return axios.post(url, formData, config);
    },
    //手机验证码
    getCode(data){
        return axios.post('/auth/getCode', data);
    },

    //////////******************///////////
    //登录
    login(data){
        return axios.post('/user_login', data);
    },
    
}