<template>
  <div>
    <component :is="item.ModuleCode" v-for="(item,index) in FooterModuleData" :key="index"></component>
  </div>
</template>
<script>
import BottomMenu from '@/components/footer/BottomMenu.vue';
import Contact from '@/components/footer/Contact.vue';
import Bottom from '@/components/footer/Bottom.vue';
import {getStore} from '@/utils/utils';
export default {
  name: 'pfooter',
  components:{
    BottomMenu,
    Contact,
    Bottom
  },
  data(){
    return{
      BottomModuleData:['BottomMenu','Contact','Bottom'],
      FooterModuleData:[]
    }
  },
  mounted(){
    let data = getStore('ModuleList');
    if(data!=null&&data!=undefined&&data!=''&&data!=[]){
      let arr = JSON.parse(data);
      this.BottomModuleData.forEach(item=>{
        let obj = arr.find(f=>f.ModuleCode===item);
        if(obj!=undefined){
          this.FooterModuleData.push(obj);
        }
      });
    }
  },
}
</script>