<template>
    <div class="dressbox">
        <div class="dress">
            <div class="add"><i class="iconfont">&#xe637;</i><span style="font-size: 24px;">{{ParamData.Web_TelPhone}}</span></div>
            <div class="add"><i class="iconfont">&#xe615;</i>{{ParamData.Web_CompanyAddress}}</div>
            <div class="clear"></div>
        </div>
    </div>
  </template>
  <script>
  import { getStore } from '@/utils/utils';
  export default {
    name: 'Contact',
    data(){
      return {
        ParamData:{}
      }
    },
    mounted(){
      let param = getStore("ParamList");
      if(param!=null&&param!=undefined&&param!=''&&param!=[]){
        JSON.parse(param).map(item => {
            this.$set(this.ParamData, item.ParamKey, item.ParamValue);
        });
      }
    },
  }
  </script>
  <style scoped lang="less">
  .dressbox{
    width: 100%;
    height: 54px;
    background-color: #3f3e3e;
    color: #ebebeb;
    .dress{
        max-width: 1420px;
        margin: auto;
        height: 100%;
        font-size: 15px;
        letter-spacing: 1px;
        overflow: hidden;
        .add{
          height: 100%;
          line-height: 100%;
          float: left;
          margin-left: 20px;
          margin-right: 30px;
          line-height: 40px;
          i{
            font-size: 20px;
            margin-right: 5px;
          }
        }
      }
  }
  </style>
    