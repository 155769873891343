import {service} from '@/api/config'
import qs from 'qs'


// /api/Recommend/GetRecommend
export default {

    Recommend: {
        // 推荐数据
        GetRecommend(data) {
            return service({
                url: '/api/Recommend/GetRecommend',
                data
            })
        },
        GetRecommendStat(data) {
            return service({
                url: '/api/Recommend/GetRecommendStat',
                data
            })
        },
        GetRecommendStatByPredictScore(data) {
            return service({
                url: '/api/Recommend/GetRecommendStatByPredictScore',
                data
            })
        },
        // 位次
        GetRecommendMajorAndCollegeGroupStat(data) {
            return service({
                url: '/api/Recommend/GetRecommendMajorAndCollegeGroupStat',
                data
            })
        },
        // 位点
        GetRecommendMajorAndCollegeGroupStatByPredictScore(data) {
            return service({
                url: '/api/Recommend/GetRecommendMajorAndCollegeGroupStatByPredictScore',
                data
            })
        },
        // 位次
        GetRecommendMajorAndCollegeList(data, page) {
            page = page || {pageIndex: 1, pageSize: 10}
            return service({
                url: '/api/Recommend/GetRecommendMajorAndCollegeList?pageIndex='+page.pageIndex+'&pageSize='+page.pageSize,
                data
            })
        },
        // 位点
        GetRecommendMajorAndCollegeListByPredictScore(data, page) {
            page = page || {pageIndex: 1, pageSize: 10}
            return service({
                url: '/api/Recommend/GetRecommendMajorAndCollegeListByPredictScore?pageIndex='+page.pageIndex+'&pageSize='+page.pageSize,
                data
            })
        },
        // 位次
        GetRecommendCollegeGroupStat(data) {
            return service({
                url: '/api/Recommend/GetRecommendCollegeGroupStat',
                data
            })
        },
        // 位点
        GetRecommendCollegeGroupStatByPredictScore(data) {
            return service({
                url: '/api/Recommend/GetRecommendCollegeGroupStatByPredictScore',
                data
            })
        },
        // 位次
        GetRecommendCollegeList(data, page) {
            page = page || {pageIndex: 1, pageSize: 10}
            return service({
                url: '/api/Recommend/GetRecommendCollegeList?pageIndex='+page.pageIndex+'&pageSize='+page.pageSize,
                data
            })
        },
        // 位点
        GetRecommendCollegeListByPredictScore(data, page) {
            page = page || {pageIndex: 1, pageSize: 10}
            return service({
                url: '/api/Recommend/GetRecommendCollegeListByPredictScore?pageIndex='+page.pageIndex+'&pageSize='+page.pageSize,
                data
            })
        },
        // 位次
        GetRecommendMajorList(data) {
            return service({
                url: '/api/Recommend/GetRecommendMajorList',
                data
            })
        },
        // 位点
        GetRecommendMajorListByPredictScore(data) {
            return service({
                url: '/api/Recommend/GetRecommendMajorListByPredictScore',
                data
            })
        },
        SearchRecommendCollege(data) {
            return service({
                url: '/api/Recommend/SearchRecommendCollege',
                data
            })
        },
        SaveZYMajorAndCollege(data) {
            return service({
                url: '/api/User/SaveZYMajorAndCollege',
                data
            })
        },
        SaveZY(data) {
            return service({
                url: '/api/User/SaveZY',
                data
            })
        },
        DelZY(data) {
            return service({
                url: '/api/Recommend/DelZY',
                data
            })
        },
    }
}