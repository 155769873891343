
/* 获取 ExamProvince */
export const getExamProvince = () => {
  return getStore('ExamProvince');
}
/* 存储 ExamProvince */
export const setExamProvince = (content) => {
  setStore('ExamProvince',content);
  let pro = getStore('OrgProvince');
  if(pro){
    let obj= content.find(f=>f.ProvinceId==JSON.parse(pro).ProvinceId);

    if(obj!=null){
      
      setStore('OrgProvince',obj);
    }
  }
}

/* 存储localStorage */
export const setStore = (name, content) => {
  if (!name) return;
  if (typeof content !== 'string') {
    content = JSON.stringify(content);
  }
  window.localStorage.setItem(name, content);
}

/* 获取localStorage */
export const getStore = name => {
  if (!name) return;
  return window.localStorage.getItem(name);
}
  
/* 删除localStorage */
export const removeStore = name => {
  if (!name) return;
  window.localStorage.removeItem(name);
}

/* 时间格式化 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export function validateRealScore(rule, value, callback) {
  if (value*1 < 0 || value*1 > 750) {
    callback(new Error('请输入有效的高考成绩！'))
  } else callback()
}

/* 获取字符长度 */
export function getByteLen(val) {
  let len = 0
  for (let i = 0; i < val.length; i++) {
    if (val[i].match(/[^\x00-\xff]/gi) != null) {
      len += 1
    } else {
      len += 0.5
    }
  }
  return Math.floor(len)
}

/* 文本html处理 */
export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

/* 获取时间戳 */
export function getTimeStamp() {
  return new Date().getTime() - 3600 * 1000 * 24 * 90
}
// 对比数据
export function getContrastList() {
  return JSON.parse(localStorage.getItem('contrastList'))
}

// 过期缓存
export function customSetStorage(name, data, expires) {
  if (!name || (!data && data !== 0)) {
    throw new Error('缺少必要参数！')
  }
  expires = expires?expires * 1000:''
  let obj = {
    data: data,
    expires: expires,
    startTime: new Date().getTime()
  }

  obj = JSON.stringify(obj)

  localStorage.setItem(name, obj)

}

export function customGetStorage(name) {
  if (!name) {
    throw new Error('缺少必要参数！')
  }
  let obj = localStorage.getItem(name)

  if(!obj) {
    return false
  }
  obj = JSON.parse(obj)

  if (obj.expires) { // 存在过期
    let temp = new Date().getTime()
    if(temp - obj.startTime > obj.expires) {
      localStorage.removeItem(name)
      return false
    } else {
      return obj.data
    }
  }

}