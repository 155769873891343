<template>
  <div class="screen-container">
    <div class="content">
      <div class="content-title">
        院校地区：
      </div>
      <el-radio-group class="content-item" v-model="query.ProvinceId" size="mini">
        <el-radio-button label="">不限</el-radio-button>
        <el-radio-button v-for="(item, index) in mainData.ProvinceList" :label="item.ID" :key="index">
          {{ item.Name }}
        </el-radio-button>
      </el-radio-group>
    </div>
    <div class="content">
      <div class="content-title">
        院校类别：
      </div>
      <el-radio-group class="content-item" v-model="query.CategoryId" size="mini">
        <el-radio-button label="">不限</el-radio-button>
        <el-radio-button v-for="(item, index) in mainData.SchoolCategoryList" :label="item.ID" :key="index">
          {{ item.CategoryName }}
        </el-radio-button>
      </el-radio-group>
    </div>
    <div class="content">
      <div class="content-title">
        办学性质：
      </div>
      <el-radio-group class="content-item" v-model="query.SchoolNature" size="mini">
        <el-radio-button label="">不限</el-radio-button>
        <el-radio-button v-for="(item, index) in mainData.SchoolNatureList" :label="item.Value" :key="index">
          {{ item.Name }}
        </el-radio-button>
      </el-radio-group>
    </div>
    <div class="content">
      <div class="content-title">
        办学层次：
      </div>
      <el-radio-group class="content-item" v-model="query.EducationLevel" size="mini">
        <el-radio-button label="">不限</el-radio-button>
        <el-radio-button v-for="(item, index) in mainData.educationLevel" :label="item.ID" :key="index">
          {{ item.Name }}
        </el-radio-button>
      </el-radio-group>
    </div>
    <div class="content">
      <div class="content-title">
        特别属性：
      </div>
      <el-radio-group class="content-item" v-model="query.Tag" size="mini">
        <el-radio-button label="">不限</el-radio-button>
        <el-radio-button v-for="(item, index) in mainData.SchoolTagList" :label="item.Value" :key="index">
          {{ item.Name }}
        </el-radio-button>
      </el-radio-group>
    </div>
    <div class="content-bottom">
      <div class="content-title">分数查询:</div>
      <div class="box">
        <el-tag v-if="tagText" closable :disable-transitions="false" @close="handleClose()">
          {{ tagText }}
        </el-tag>
        <el-form label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm" :inline="true">
          <el-form-item label="招生地区" prop="ProvinceId">
            <el-select v-model="ruleForm.ProvinceId" placeholder="请选择" @change="judgeNewExamStatus()">
              <el-option label="请选择" value></el-option>
              <el-option v-for="item in mainData.ProvinceList" :key="item.ID" :label="item.Name"
                :value="item.ID"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="查询年份" prop="Year">
            <el-date-picker @change="judgeNewExamStatus()" v-model="ruleForm.Year" type="year"
              placeholder="选择年份" value-format="yyyy"></el-date-picker>
          </el-form-item>

          <template v-if="!isNewExam">
            <div class="subject screen-item">
              <el-form-item label="科类" prop="SubjectType">
                <el-radio-group v-model="ruleForm.SubjectType" @change="getBatchList">
                  <el-radio-button label="1">文科</el-radio-button>
                  <el-radio-button label="2">理科</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </div>
          </template>
          <template v-else-if="isNewExam && newExamActive">
            <div class="new-exam screen-item">
              <el-form-item label="科目" prop="SelectSubject">
                <el-radio-group v-model="ruleForm.SelectSubject" @change="getBatchList">
                  <el-radio-button label="物理">物理</el-radio-button>
                  <el-radio-button label="历史">历史</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </div>
          </template>
          <el-form-item label="批次">
            <el-select v-model="ruleForm.BatchId" placeholder="请选择">
              <el-option label="请选择" value></el-option>
              <el-option v-for="item in BatchList" :key="item.BatchId" :label="item.BatchName"
                :value="item.BatchId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="'录取分数低于' + ruleForm.Score + '分'" prop="Score">
            <el-input-number placeholder="录取分数" v-model="ruleForm.Score" controls-position="right"
              :max="750"></el-input-number>
          </el-form-item>
          <!-- <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">按最低分查询</el-button>
          </el-form-item> -->
        </el-form>
        
      </div>
    </div>
    <div style="text-align: center;">
      <el-button type="primary" @click="submitForm('ruleForm')">按最低分查询</el-button>
    </div>
    <div class="submit">
      <el-button class="submit-button" @click="submitScreen" type="primary" plain size="small"
        :loading="screenButtonLoading">{{ screenButtonLoading ? '搜索中...' : '确认筛选' }}</el-button>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'

export default {
  name: "ConditionScreen",
  props: {
    conditionConfig: Array,
    sign: String,
  },
  data() {
    return {
      mainData: {},
      screenButtonLoading: false,
      query: {
        CategoryId: '',
        EducationLevel: '',
        ProvinceId: '',
        SchoolNature: '',
        Tag: '',
      },
      isNewExam: false,
      newExamActive: false,
      ruleFormLs: {},
      ruleForm: {
        ProvinceId: "",
        ProvinceIdName: "",
        Year: "",
        SubjectType: '1',
        SelectSubject: "物理",
        BatchId: "",
        BatchName: "",
        Score: "",
      },
      rules: {
        Year: [
          { required: true, message: "请选择查询年份", trigger: "change" },
        ],
        ProvinceId: [
          { required: true, message: "请选择招生地区", trigger: "change" },
        ],
        Score: [{ required: true, message: "请填写分数！", trigger: "blur" }],
        SubjectType: [
          { required: true, message: "请选择科类！", trigger: "change" },
        ],
        SelectSubject: [
          { required: true, message: "请选择科目！", trigger: "change" },
        ],
      },
      BatchList: [],
      tagText: "",
    }
  },
  mounted() {
    this.initialize()
    this.$globalEventBus.$on('screenButtonLoading', data => {
      this.screenButtonLoading = data
    })
  },
  methods: {
    handleClose() {
      this.tagText = "";
      this.query.SchoolHisotyScore = null;
      this.ruleForm = {
        ProvinceId: "",
        ProvinceIdName: "",
        Year: "",
        SubjectType: 1,
        SelectSubject: "物理",
        BatchId: "",
        BatchName: "",
        Score: "",
      };
      this.$ls.remove("ruleFormLs");
      this.submitScreen();
    },
    getBatchList() {
      // this.$refs.ruleForm.validate((valid) => {
      if (this.ruleForm.ProvinceId && this.ruleForm.Year) {
        API.Public.ProvinceBatchList({
          ProvinceId: this.ruleForm.ProvinceId,
          Year: Number(this.ruleForm.Year),
          SubjectType: Number(this.ruleForm.SubjectType),
          SelectSubject: this.ruleForm.SelectSubject,
        }).then((res) => {
          if (res.data && res.data.length > 0) {
            this.BatchList = res.data;
          } else {
            this.BatchList = [];
          }
          this.ruleForm.BatchId = "";
        });
      }
      this.$forceUpdate()
      // });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const SchoolHisotyScore = {
            ProvinceId: this.ruleForm.ProvinceId,
            Year: Number(this.ruleForm.Year),
            SubjectType: Number(this.ruleForm.SubjectType),
            SelectSubject: this.ruleForm.SelectSubject,
            BatchId: this.ruleForm.BatchId || null,
            // BatchId: "2dd72669-63b9-497e-9d6f-6417dd9cb27d",
            Score: this.ruleForm.Score,
          };
          this.$ls.set('ruleFormLs', this.ruleForm);
          this.ruleFormLs = this.$ls.get('ruleFormLs');
          this.tagText =
            this.ruleFormLs.ProvinceIdName +
            "+" +
            this.ruleFormLs.Year +
            "年+录取分数低于" +
            this.ruleFormLs.Score +
            "分";
          this.query.SchoolHisotyScore = SchoolHisotyScore;
          // this.currentPage = 1;
          this.submitScreen();
        } else {
          this.$message("请检查分数查询板块必填项");
        }
      });
    },

    //新高考状态判定
    judgeNewExamStatus() {
      if (this.ruleForm.ProvinceId && this.ruleForm.Year) {
        this.$store
          .dispatch("user/judgeNewExam", {
            id: this.ruleForm.ProvinceId,
            year: this.ruleForm.Year,
          })
          .then((newExam) => {
            this.isNewExam = newExam.IsNewExam;
            if (newExam.IsNewExam) {
              this.$delete(this.ruleForm, "SubjectType");
              if (newExam.RuleList.length > 1) {
                this.newExamActive = true;
                this.ruleForm.SelectSubject = "物理";
              } else {
                this.newExamActive = false;
                this.ruleForm.SelectSubject = "";
              }
            } else {
              this.$delete(this.ruleForm, "SelectSubject");
              this.ruleForm.SubjectType = '1';
            }
            setTimeout(() => {
              this.getBatchList();
            })
          });
      }
      if (this.ruleForm.ProvinceId) {
        const index = this.mainData.ProvinceList.findIndex(
          (ele) => ele.ID === this.ruleForm.ProvinceId
        );
        if (index > -1) {
          this.ruleForm.ProvinceIdName =
            this.mainData.ProvinceList[index].Name;
        }
      }
    },
    batchChange(val) {
      if (val) {
        const index = this.BatchList.findIndex((ele) => ele.BatchId === val);
        if (index > -1) {
          this.ruleForm.BatchName =
            this.mainData.ProvinceList[index].BatchName;
        }
      }
    },
    //提交筛选参数
    submitScreen() {
      if (this.sign === 'search-university') {
        this.screenButtonLoading = true
        this.$globalEventBus.$emit('screenParameters', this.query)
      }
    },
    //初始化数据
    initialize() {
      this.getScreenList()
    },
    async getScreenList() {
      this.mainData = (await API.School.BaseCondition()).data
      this.mainData.educationLevel = [
        { Name: '本科', ID: 1 },
        { Name: '高职高专', ID: 0 }
      ]
    }
  }
}
</script>

<style scoped lang="less">
.screen-container {
  padding: 2rem;
  border: 1px solid var(--color-grey);
  background-color: #fff;

  .content {
    margin-bottom: 1rem;
    display: flex;

    &-title {
      width: 10%;
      font-size: var(--text-font-size);
      margin-right: 3rem;
    }

    &-item {
      width: 90%;

      ::v-deep .el-radio-button {
        margin: 0 1rem .5rem 0;

        .el-radio-button__inner {
          padding: .3rem 1.2rem;
          border: 1px solid var(--color-grey);
          border-radius: 1rem;
        }

        .el-radio-button__orig-radio:checked+.el-radio-button__inner {
          box-shadow: none;
        }
      }
    }
  }

  .content-bottom {
    display: flex;
    .content-title {
      width: 93px;
    }
    .box {
      width: 900px;
      // height: 130px;
    }
  }

  .submit {
    margin-top: 40px;
    text-align: center;

    &-button {
      width: 6rem;
    }
  }
}
.screen-item {
  min-width: 160px;
  text-align: center;
}
::v-deep .el-form {
  display: flex;
}
::v-deep .el-date-editor.el-input {
  width: 100%;
}
</style>