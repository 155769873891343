<template>
  <div class="custom-tree">
    <div class="tree-inner">
      <el-tree
        :data="mainData"
        :props="defaultProps"
        node-key="ID"
        ref="tree"
        show-checkbox
        check-strictly
        :expand-on-click-node="false"
        :filter-node-method="handleFilterNode"
        @check="handleNodeClick"
      ></el-tree>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'
// import { Area } from "@/api/common";
export default {
  name: "CustomTree",
  props: {
    changeNode: {
      type: Array,
      default() {
        return [];
      },
    },
    filterNode: {
      type: String,
      default: "",
    },
    mainData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      // mainData: [],
      defaultProps: {
        children: "SubList",
        label: "Name",
      },
    };
  },
  watch: {
    filterNode(value) {
      this.filterSearchNode(value);
    },
    changeNode(value) {
      this.setNodeStatus(value);
    },
  },
  mounted() {
    // this.initializeData();
  },
  methods: {
    //filter node for search keyword
    filterSearchNode(value) {
      this.$refs.tree.filter(value);
    },
    handleFilterNode(value, data) {
      if (!value) return true;
      return data.Name.indexOf(value) !== -1;
    },
    //set node for change status
    setNodeStatus(changeNode) {
      this.$refs.tree.setCheckedKeys(changeNode);
    },

    //click node
    handleNodeClick(node) {
      this.$emit("selectNode", node);
      this.$refs.tree.filter(); // filter中不传值，就表示把所有节点都显示出来
    },
    //initialize data
    // initializeData() {
    //   API.GetAreaOptions().then((res) => {
    //     res.data.forEach((item) => {
    //       item.ChildrenList.forEach((subItem) => {
    //         this.$delete(subItem, "ChildrenList");
    //       });
    //     });
    //     this.mainData = res.data;
    //   });
    // },
  },
};
</script>

<style scoped lang="less">
.custom-tree {
  position: relative;
  .tree-inner {
    width:100%;
    height: 18rem;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
  }
}
</style>
