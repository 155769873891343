import axios from 'axios';
import Config from '@/config/config';
import { getStore } from "@/utils/utils";
import router from '@/router'
import { removeStore } from '@/utils/utils'
import { Message } from 'element-ui'

let Api = {};
//导入modules模块
const modulesContext = require.context('./modules/', true, /\.js$/);
modulesContext.keys().reduce((modules, key) => {
  Api = {
    ...Api,
    ...modulesContext(key).default
  }
}, {});

// 添加请求拦截器
axios.interceptors.request.use(config => {
  
  //在发送请求之前做某事
  config.baseURL = (APIHost ? APIHost : Config.APIHost);
  if (!config.headers['Content-Type']) {
    config.headers['Content-Type'] = "application/json"
    // config.headers['Content-Type'] ="application/x-www-form-urlencoded"
  }

  let info = getStore('LoginData');
  if(info){
    config.headers['token'] = JSON.parse(info).Token;
  }
  config.headers['orgCode'] = getStore('OrgCode');
  config.headers['terminalType'] = 0;
  // if(config.url!="/admin_login"){
  //   config.headers['Authorization'] = getStore('Admin-Token');
  // }
  if (config.method.toUpperCase() === 'GET') {
    config.data = true
  }
  return config;
}, error => {
  //请求错误时做些事
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(response => {
  //对响应数据做些事
  if (response.status == 200) {
    
    if(response.data.Code === 112) {
      Message({
        type: 'error',
        message: '登录过期，请重新登录！'
      })
      
      removeStore('LoginData')

      setTimeout(() => {
        router.push('/login')

      }, 800)
      return response.data
    }


    return response.data;
  } else {
    return response;
  }
}, error => {
  //请求错误时做些事
  return Promise.reject(error);
});


// 配置
const service = axios.create({

  // 接口配置中相同项可覆盖下面的值

  baseURL: APIHost ? APIHost : Config.APIHost,

  method: 'POST', // 接口配置可覆盖该项

  timeout: 40000,

  withCredentials: false,

  transformResponse: [
    data => {
      return JSON.parse(data)
    }
  ]
})

// 添加请求拦截器
service.interceptors.request.use(config => {
  //在发送请求之前做某事
  config.baseURL = (APIHost ? APIHost : Config.APIHost);
  if (!config.headers['Content-Type']) {
    config.headers['Content-Type'] = "application/json"
    // config.headers['Content-Type'] ="application/x-www-form-urlencoded"
  }

  config.headers['orgCode'] = getStore('OrgCode');

  config.headers['terminalType'] = 0;
  
  let token = getStore('LoginData');

  if(token){
    config.headers['token'] = JSON.parse(getStore('LoginData')).Token;
  }

  if (config.method.toUpperCase() === 'GET') {
    config.data = true
  }
  return config;
}, error => {
  //请求错误时做些事
  return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(response => {

  //对响应数据做些事
  if (response.status == 200) {



    if(response.data.Code === 112) {
      Message({
        type: 'error',
        message: '登录过期，请重新登录！'
      })
      
      removeStore('LoginData')

      setTimeout(() => {
        router.push('/login')

      }, 800)
      return
    }


    let data = {
      code: response.data.Code,
      data: response.data.Data,
      message: response.data.Message
    }
    return data;
  } else {
    return response;
  }
}, error => {
  //请求错误时做些事
  return Promise.reject(error);
});


export { service }


export default Api;