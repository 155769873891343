
//接口地址配置
let urlStr = "http://192.168.5.220:8203";

export default {
  APIHost: urlStr
};

//浏览器高宽获取
export const CWidth = document.body.clientWidth || document.documentElement.clientWidth;//页面可视区域宽度
export const IWidth = window.innerWidth;//浏览器窗口宽度
export const CHeight = document.body.clientHeight || document.documentElement.clientHeight;//页面可视区域高度
export const IHeight = window.innerHeight;//浏览器窗口高度


window.PLATFROM_CONFIG = {};
// 分数范围配置
// 最大、最小分数范围
PLATFROM_CONFIG.ScoreLimitMin = -100;
PLATFROM_CONFIG.ScoreLimitMax = 80;
// 默认分数
PLATFROM_CONFIG.ScoreLowerLimit = -30;
PLATFROM_CONFIG.ScoreUpperLimit = 15;
// 用户手册地址
// PLATFROM_CONFIG.HelpPDFUrl = "http://192.168.5.220:8091/help.pdf"
// 客服电话
// PLATFROM_CONFIG.ServiceTel = "4009 027 028"

