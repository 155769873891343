import {service} from '@/api/config'
import qs from 'qs'

let bodyHeaders = {
    "Content-Type": "application/json; charset=utf-8"
}

export default {

    Public: {

        ProvinceWishCount(data) {
            return service({
                url: '/api/Public/ProvinceWishCount',
                headders: bodyHeaders,
                data
            })
        },

        ProvinceBatchList(data) {
            return service({
                url: '/api/Public/ProvinceBatchList',
                headers: bodyHeaders,
                data
            })
        },
        ProvinceIsFinalScore(data) {
            return service({
                url: '/api/Public/ProvinceIsFinalScore',
                headers: bodyHeaders,
                data
            })
        },
        ProvinceList() {
            return service({
                url: '/api/Public/ProvinceList',
            })
        },
        ProvinceIsNewExam(data) {
            return service({
                url: '/api/Public/ProvinceIsNewExam',
                headers: bodyHeaders,
                data
            })
        },
    
        // 查位次
        LineRank(data) {
            return service({
                url: '/api/Public/LineRank',
                headers: bodyHeaders,
                data
            })
        },
        LineRankYears(data) {
            return service({
                url: '/api/Public/LineRankYears',
                headers: bodyHeaders,
                data
            })
        },
        LineRankList(data) {
            return service({
                url: '/api/Public/LineRankList',
                headers: bodyHeaders,
                data
            })
        },
        // 查批次
        LineBatchList(data) {
            return service({
                url: '/api/Public/LineBatchList',
                headers: bodyHeaders,
                data
            })
        },
    
        ProvinceWishCount(data) {
            return service({
                url: '/api/Public/ProvinceWishCount',
                headers: bodyHeaders,
                data
            })
        },

        NewExamSubject(data) {
            return service({
                url: '/api/Public/NewExamSubject',
                headers: bodyHeaders,
                data
            })
        },

        // 新高考选科
        NewExamProvince() {
            return service({
                url: '/api/Public/NewExamProvince',
                headers: bodyHeaders
            })
        },
        NewExamProvinceYear(data) {
            return service({
                url: '/api/Public/NewExamProvinceYear',
                headers: bodyHeaders,
                data
            })
        },
        NewExamSubject(data) {
            return service({
                url: '/api/Public/NewExamSubject',
                headers: bodyHeaders,
                data
            })
        },

        // 诊断成绩
        LineDiagnosisList(data) {
            return service({
                url: '/api/Public/LineDiagnosisList',
                headers: bodyHeaders,
                data
            })
        },
        LineDiagnosisArea(){
            return service({
                url: '/api/Public/LineDiagnosisArea',
                headers: bodyHeaders
            })
        },
        LineDiagnosisPaged(data){
            return service({
                url: '/api/Public/LineDiagnosisPaged',
                headers: bodyHeaders,
                data
            })
        },
        LineDiagnosisScore(data) {
            return service({
                url: '/api/Public/LineDiagnosisScore',
                headers: bodyHeaders,
                data
            })
        },
        // 招生计划
        EnrollmentPlan(data) {
            return service({
                url: '/api/Public/EnrollmentPlan',
                headers: bodyHeaders,
                data
            })
        },
        // 录取数据
        LineSpecialtyScoreList(data) {
            return service({
                url: '/api/Public/LineSpecialtyScoreList',
                headers: bodyHeaders,
                data
            })
        },
        LineSchooScoreList(data) {
            return service({
                url: '/api/Public/LineSchooScoreList',
                headers: bodyHeaders,
                data
            })
        },
        LineSchoolScoreBatchList(data) {
            return service({
                url: '/api/Public/LineSchoolScoreBatchList',
                headers: bodyHeaders,
                data
            })
        },
        LineSpecialtyScoreBatchList(data) {
            return service({
                url: '/api/Public/LineSpecialtyScoreBatchList',
                headers: bodyHeaders,
                data
            })
        },
    },
    
    Options: {

        GetAreaOptions() {
            return service({
                url: '/api/Options/GetAreaOptions',
            })
        },
        
        GetSchoolOptions(data) {
            let value = qs.stringify(data)
            return service({
                url: '/api/Options/GetSchoolOptions',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: value
            })
        },

        // 角色选项
        // /api​/Options​/GetRoleOptions
        GetRoleOptions(data) {
            let value = qs.stringify(data)
            return service({
                url: '/api/Options/GetRoleOptions',
                data: value
            })
        },

        // 用户状态选项
        // /api/Options/GetEnumStatusOptions
        GetEnumStatusOptions() {
            return service({
                url: '/api/Options/GetEnumStatusOptions'
            })
        },

        // 所属机构GetOrgOptions
        GetOrgOptions(data) {
            let value = qs.stringify(data)
            return service({
                url: '/api/Options/GetOrgOptions',
                data: value
            })
        },

        GetProductOptions() {
            return service({
                url: '/api/Options/GetProductOptions',
            })
        },

    },

    Payment: {

        WechatQRCodePay(data) {
            return service({
                url: '/api/Payment/WechatQRCodePay',
                data
            })
        },
        PaymentStatus(data) {
            return service({
                url: '/api/Payment/PaymentStatus',
                data
            })
        }
    }

}