import API from '@/api/config'
import { getContrastList, getStore, setStore, removeStore } from "@/utils/utils";
import { Message } from 'element-ui';
import router from '@/router'
import {areaData} from '@/utils/data';
const getDefaultState = () => {
  return {

    contrastList: getContrastList() || [],
    // memberNumber: getStore('ProductCodes') || []

    nameConfig: {
      WCF: "位次法",
      WDF: "位点法"
    }
  }
}

const state = getDefaultState()

const mutations = {

  SET_CONTRASTLIST: (state, contrastList) => {
    state.contrastList = contrastList
  },

  set_nameConfig: (state, params) => {
    state.nameConfig = params
  }
}

const actions = {

  // 退出
  loginOut() {
    return new Promise((resolve, reject) => {
      API.Web.Loginout().then(res => {

        removeStore('LoginData')
        router.push('/login')

        resolve()
      }).catch(err => {
        Message({ type: 'error', message: err.Message })
        reject()
      })
    })
  },

  // 会员限制方法
  // params query 用户拥有其中一个权限，就可通过
  allowMember(params, query) {
    return new Promise((resolve, reject) => {
      if (query) {
        let tempFlag = Array.isArray(query)
        if (tempFlag) {
          params.dispatch('judgeLoginStatus').then(flag => {
            if (flag) {
              let memberNumber = getStore('RightsList')
              if (memberNumber) {
                memberNumber = JSON.parse(memberNumber)
              } else {
                memberNumber = []
              }
              if (query.length !== 0) {

                if (query.includes('any')) {
                  if (memberNumber.length === 0) {
                    resolve(false)
                  } else {
                    resolve(true)
                  }

                } else {

                  if (memberNumber.length === 0) {
                    resolve(false)
                  } else {
                    let allowFlag = memberNumber.find(item => query.includes(item.RightsCode))

                    // allowFlag ? resolve(true) : resolve(false)

                    if (allowFlag) {

                      // 权限是否过期
                      let tempDate = new Date(allowFlag.ExpireAt)
                      let nowDate = new Date()

                      tempDate.getTime() > nowDate.getTime() ? resolve(true) : resolve(false)

                    } else {
                      resolve(false)
                    }
                  }

                }

              } else {
                resolve(true)
              }
            }
            else{
              router.push('/login')
            }
          })
        } else {
          Message.info('权限参数非数组类型！')
          resolve(false)
        }
      } else {
        Message.info('未设置权限参数！')
        resolve(true)
      }
    })
  },

  // 获取个人信息
  getStudentInfo() {
    return new Promise((resolve, reject) => {
      API.Personal.GetUserStudent().then(res => {

        resolve(res.Data)
      })
    })
  },

  //判断省份是否开通了新高考
  judgeNewExam({ commit }, query) {
    const { id, year } = query || {}
    return new Promise((resolve, reject) => {
      API.Public.ProvinceIsNewExam({
        ProvinceId: areaData[id],
        Year: Number(year)
      }).then(res => {
        resolve(res.data)
      })
    })
  },

  getPowerProvinceList() {
    return new Promise(resolve => {
      API.Public.ProvinceList().then(data => {
        resolve(data.data)
      })
    })
  },

  //院校对比列表数据添加
  setContrastList({ commit }, list) {
    return new Promise(resolve => {
      commit('SET_CONTRASTLIST', list)
      resolve()
    })
  },

  // 登录状态判定
  judgeLoginStatus({ }) {
    return new Promise((resolve, reject) => {
      API.Web.LoginStatus().then(res => {
        if (res.Data !== null) {
          setStore('LoginData', res.Data)
          setStore('RightsList', res.Data.RightsList)
          resolve(true)
        } else {
          removeStore('LoginData')
          resolve(false)
        }
      }).catch(err => {
        // removeStore('LoginData')
        // resolve(false)
      })
    })
  },
  // info compate
  judgeInfoComplete() {
    return new Promise(resolve => {
      API.User.CheckUserStudentComplate().then(({ data: infoStatus }) => {
        if (infoStatus.IsNeedComplateBaseInfo) {

          Message.info('请先完善基本信息！')
          router.push('/userinfo?source=TB')

          return
        }

        if (infoStatus.IsNeedComplateScore) {

          Message.info('请先完善高考信息！')
          router.push('/examinee?source=TB')

          return
        }

        if (infoStatus.IsNeedComplateFinalScore) {

          Message.info('请先完善高考成绩！')
          router.push('/setRealScore?source=TB')

          return
        }

        if (infoStatus.IsNeedComplateSubjects) {

          Message.info('请先完善选科！')
          router.push('/examinee?source=TB')

          return
        }

        resolve()
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
