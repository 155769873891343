import {service} from '@/api/config'
import qs from 'qs'

let bodyHeaders = {
    "Content-Type": "application/json; charset=utf-8"
}

export default {
    Course: {
        ResourceInfo(data) {
            return service({
                url: '/api/Course/ResourceInfo',
                headers: bodyHeaders,
                data
            })
        },
        ResourceList(data) {
            return service({
                url: '/api/Course/ResourceList',
                headers: bodyHeaders,
                data
            })
        },
        SubjectList(data) {
            return service({
                url: '/api/Course/SubjectList',
                headers: bodyHeaders,
                data
            })
        },
        CatelogList(data) {
            return service({
                url: '/api/Course/CatelogList',
                headers: bodyHeaders,
                data
            })
        },
        ResourceListBySearch(data) {
            return service({
                url: '/api/Course/ResourceListBySearch',
                headers: bodyHeaders,
                data
            })
        },
        GradeList(data) {
            return service({
                url: '/api/Course/GradeList',
                headers: bodyHeaders,
                data
            })
        }
    }
}