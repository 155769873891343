import Vue from 'vue'
import API from "@/api/config";
import './echarts_options'

const isVue = new Vue()
//storage
Vue.prototype.$setStorage = function(name, value){
  return new Promise((resolve,reject) => {
    localStorage.setItem(name, JSON.stringify(value))
    resolve()
  })
}
Vue.prototype.$getStorage = function(name) {
  return new Promise((resolve, reject) => {
    let temp = localStorage.getItem(name)
    if(temp !== null){
      temp = JSON.parse(temp)
      resolve(temp)
    }else{
      reject()
    }
  })
}

Vue.prototype.$messageTips = function(type, message){
  isVue.$message({
    type: type,
    message: message,
    showClose: true,
    center: true,
    offset: 120
  })
}

Vue.prototype.$confirmTips = function (message, title, type) {
  return new Promise((resolve, reject) => {
    isVue.$confirm(message, title, {
      type: type||'',
      center: true,
      dangerouslyUseHTMLString: true
    }).then(res =>{
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

Array.prototype.$remove = function (element, key) {
  const arrayObject = this
  if(key){
    arrayObject.forEach((item, index, array) => {
      if(item[key] === element[key]){
        array.splice(index, 1)
      }
    })
    return arrayObject
  }else{
    let index = arrayObject.indexOf(element)
    if(index > -1){
      arrayObject.splice(index, 1)
    }
    return arrayObject
  }
}

Array.prototype.$removeOfProperty = function (property, value) {
  const arrayObject = this
  arrayObject.forEach((item, index, array) => {
    if(item[property] === value){
      array.splice(index, 1)
    }
  })
  return arrayObject
}
// 省份是否使用高考总分
Vue.prototype.$getProvinceIsFinalScore = function (ProvinceId,callback) {
    API.Public.ProvinceIsFinalScore({
      ProvinceId:ProvinceId
    }).then(res =>{
      const flag = res.data.IsFinalScore
      callback(flag);
    }).catch(()=>{
      callback(false);
    })
}

/**
 * 全局数组实例方法
 * */
//删除数组中指定项
Array.prototype.removeArrayItem = function (item) {
  let array = this, index
  if (item) {
    index = array.indexOf(item)
    if (index > -1) {
      array.splice(index, 1)
    }
    array = null
    index = null
  }
}
Array.prototype.remove = function (e, key) {
  const arr = this
  if (key) {
    arr.forEach((item, index, array) => {
      if (item[key] === e[key]) {
        array.splice(index, 1)
      }
    })
    return arr
  } else {
    let i = arr.indexOf(e)
    arr.splice(i, 1)
    return arr
  }
}
Array.prototype.has = function (e, key) {
  const arr = this
  let has = false
  if (key) {
    arr.forEach((item) => {
      if (item[key] === e[key]) {
        has = true
      }
    })
  } else {
    if (arr.indexOf(e) > -1) has = true
  }
  return has
}
window.openLoading = function (){
  window.loading = isVue.$loading({
    lock: true,
    text: '加载中...',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.5)'
  })
}
window.closeLoading = function () {
  if(window.loading) window.loading.close()
  window.loading = null
}