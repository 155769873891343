<template>
    <div>
        <div class="headertop">
            <div class="autobox">
                <div class="tel">全国服务热线：<span>{{ParamData.Web_TelPhone}}</span></div>
                <div class="rbox">
                <!-- <div class="rb" @click="agentbtn()"><i class="iconfont ico">&#xe602;</i><span style="margin-left:1px;">代理商加盟</span></div> -->
                <div class="rb" v-if="ParamData.APP_WechatMiniAPP">
                    <i class="iconfont ico">&#xe61e;</i><span style="margin-left:1px;">微信小程序</span>
                    <div class="code">
                    <i class="iconfont san">&#xe810;</i>
                    <div class="m"><img :src="ParamData.APP_WechatMiniAPP" alt=""/></div>
                    </div>
                </div>
                <div class="rb" v-if="ParamData.APP_H5">
                    <i class="iconfont ico">&#xe614;</i><span style="margin-left:3px;">移动端H5</span>
                    <div class="code">
                    <i class="iconfont san">&#xe810;</i>
                    <div class="m"><img :src="ParamData.APP_H5" alt=""/></div>
                    </div>
                </div>
                <div class="rb" v-if="ParamData.APP_Android">
                    <i class="iconfont ico">&#xe66b;</i><span>手机APP(安卓)</span>
                    <div class="code">
                    <i class="iconfont san">&#xe810;</i>
                    <div class="m"><img :src="ParamData.APP_Android" alt=""/></div>
                    </div>
                </div>
                <div class="rb" v-if="ParamData.APP_IOS">
                    <i class="iconfont ico">&#xe66a;</i><span>手机APP(IOS)</span>
                    <div class="code">
                    <i class="iconfont san">&#xe810;</i>
                    <div class="m"><img :src="ParamData.APP_IOS" alt=""/></div>
                    </div>
                </div>
                <div class="rb" v-if="false">
                    <i class="iconfont ico" style="font-size: 20px;">&#xe822;</i><span style="margin-left:3px;">换肤</span>
                    <div class="color_box">
                    <i class="iconfont san">&#xe810;</i>
                    <div class="colorlist">
                        <div class="cbox c1" @click.prevent="changeTheme('0, 118, 191')"><span class="co"></span><span class="tt">天空蓝</span><div class="clear"></div></div>
                        <div class="cbox c2" @click.prevent="changeTheme('232, 76, 51')"><span class="co"></span><span class="tt">中国红</span><div class="clear"></div></div>
                        <div class="cbox c3" @click.prevent="changeTheme('11, 109, 95')"><span class="co"></span><span class="tt">森林绿</span><div class="clear"></div></div>
                        <div class="cbox c4" @click.prevent="changeTheme('126, 22, 113')"><span class="co"></span><span class="tt">艺术紫</span><div class="clear"></div></div>
                    </div>
                    </div>
                </div>
                <div class="rb" @click="$router.push('/helpcenter')"><i class="iconfont ico" style="font-size: 20px;">&#xe654;</i><span style="margin-left:3px;">帮助</span></div>
                <div class="clear"></div>
                </div>
                <div class="clear"></div>
            </div>
            <div class="clear"></div>
        </div>
        <div class="clear"></div>
    </div>
</template>
<script>
import {getStore, setStore} from '../../utils/utils';
import { Message} from 'element-ui';
export default {
  'name':'Top',
  data(){
    return {
      ParamData:{}
    }
  },
  mounted(){
    let param = getStore("ParamList");
    if(param!=null&&param!=undefined&&param!=''&&param!=[]){
      JSON.parse(param).map(item => {
          this.$set(this.ParamData, item.ParamKey, item.ParamValue);
      });
      this.changeTheme(this.ParamData.Web_Skin);//换肤
    }
  },
  methods:{
    //代理商加盟
    agentbtn(){
      Message('功能开发中……');
    },
    //换肤
    changeTheme(color){
      setStore("ThemeColor",color);
      document.documentElement.style.setProperty("--themecolor", color);
    },
    //帮助
    helpbtn(){
      Message('功能开发中……');
    },
  }
}
</script>
<style lang="less" scoped>
/***** 顶部产品栏 *****/
.headertop{
    width: 100%;
    height: 40px;
    background-color: #f8e9d9;
    .autobox{
      max-width: 1420px;
      margin: auto;
      height: 100%;
      .tel{
        color: #666;
        font-size: 12px;
        margin-left: 20px;
        float: left;
        line-height: 40px;
        span{
          font-size: 14px;
          color: #f66;
          font-weight: bold;
        }
      }
      .rbox{
        float: right;
        margin-right: 20px;
        .rb{
          float: left;
          color: #888;
          font-size: 12px;
          margin-left:20px;
          line-height: 38px;
          position: relative;
          user-select: none;
          .code{
            width: 100px;
            height: 0px;
            text-align: center;
            position: absolute;
            top: 35px;
            z-index: 6;
            overflow: hidden;
            transition: height 0.3s;
            -webkit-transition: height 0.3s;
            .m{
              overflow: hidden;
              border: 1px solid #ddd;
              background-color: #fff;
              padding: 8px;
              img{
                width: 100%;
                display: block;
              }
            }
            i.san{
              color: #cac9c9;
              font-size: 16px;
              transform: rotate(180deg);
              width: 100%;
              text-align: center;
              display: block;
              line-height: normal;
            }
          }
          i.ico{
            font-size: 22px;
            float: left;
            line-height: 40px;
          }
        }
        .rb:hover{
          color: #666;
          cursor: pointer;
        }
        .rb:hover i{
          color: rgb(var(--themecolor));
        }
        .rb:hover .code{
          height: 120px;
        }
        .rb:hover .color_box{
          height: 200px;
        }
        /*换肤*/
        .color_box{
          width: 120px;
          height: 0px;
          position: absolute;
          top: 40px;
          left: -35px;
          z-index: 6;
          overflow: hidden;
          transition: height 0.3s;
          -webkit-transition: height 0.3s;
          i.san{
            color: #cac9c9;
            font-size: 16px;
            transform: rotate(180deg);
            width: 100%;
            text-align: center;
            display: block;
            line-height: normal;
          }
          .colorlist{
            width: calc(100% - 2px);
            border: 1px solid #ddd;
            padding: 10px 0;
            background-color: #fff;
            .cbox{
              width: calc(100% - 22px);
              margin: 5px 10px 5px;
              height: 31px;
              line-height: 30px;
              border: 1px solid #dbdbdb;
              .co{
                display: block;
                width: 16px;
                height: 16px;
                float: left;
                margin-left: 20px;
                margin-top: 8px;
              }
              .tt{
                color: #555;
                font-size: 14px;
                margin-left: 10px;
                float: left;
              }
            }
            .cbox.c1:hover{
              border: 1px solid rgb(0, 118, 191);
            }
            .cbox.c2:hover{
              border: 1px solid rgb(232, 76, 51);
            }
            .cbox.c3:hover{
              border: 1px solid rgb(11, 109, 95);
            }
            .cbox.c4:hover{
              border: 1px solid rgb(126, 22, 113);
            }
            .cbox.c1 .co{
              background-color: rgb(0, 118, 191);
            }
            .cbox.c2 .co{
              background-color: rgb(232, 76, 51);
            }
            .cbox.c3 .co{
              background-color: rgb(11, 109, 95);
            }
            .cbox.c4 .co{
              background-color: rgb(126, 22, 113);
            }
          }
        }
      }
    }
  }
</style>